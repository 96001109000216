import { Modal } from "antd";
import { VideoPanel } from "../customPolotno/customVideoSearchPanel";
import { VideoPanelStoryBoard } from "../customPolotno/customVideoSearchPanelStoryBoard";

export const SelectCustomSearchModal = ({
  visible,
  setVisible,
  store,
  index,
  searchQueries,
  callBack
}: any) => {
  return (
    <Modal
      title="Select Media"
      open={visible}

      onOk={() => {
        setVisible(false);
        // store.openSidePanel("video-timeline");
      }}
      onCancel={() => {
        setVisible(false);
        // store.openSidePanel("video-timeline");
      }}
      destroyOnClose={true}
      styles={{ body: { maxHeight: "70vh", overflowY: "auto" } }}
      // size="small" // options: "small", "middle", "large"
      // width={window.innerWidth > 768 ? 600 : 400} 
      // size={'middle'}
    >
      <VideoPanelStoryBoard
        store={store}
        elementID={""}
        index={index}
        isNotPanel={true}
        searchQueries={searchQueries}
        handleAdd={callBack}
        // displaysearchQuery={searchQuery}
      />
    </Modal>
  );
};
