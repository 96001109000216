/* eslint-disable react/prop-types */
import { useGlobalContext } from '../../../../context/globalContext'
import { VideoPlayer } from '../../player/videoPlayer'

interface BulkCreateTabPanelProps {
  store: any
  width: number
  height: number
  htmlCode: any
  duration: number
  jsonData: any
  burstImagesTransitionAnimationArray: any
  videoStyle: string
  scriptDetails: any
}

export const BulkCreateTabPanel: React.FC<BulkCreateTabPanelProps> = ({
  store,
  width,
  height,
  htmlCode,
  duration,
  jsonData,
  burstImagesTransitionAnimationArray,
  videoStyle,
  scriptDetails,
}) => {
  const {
    TransitionAnimation: [transitionAnimation],
    UserDetail: [userDetails, setUserDetails],
  } = useGlobalContext()

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // Use the full width of the viewport
      }}
    >
      <VideoPlayer
        htmlData={htmlCode}
        duration={duration}
        scriptDetails={scriptDetails}
        jsonData={jsonData}
        transitionDurationInFrames={transitionAnimation[0].duration}
        transitionAnimationProp={transitionAnimation[0].animation}
        isDownload={false}
        burstImagesTransitionAnimationArray={burstImagesTransitionAnimationArray}
        videoStyle={videoStyle}
        aiAvatar={userDetails.purpose?.aiAvatar}
      />
    </div>
  )
}
