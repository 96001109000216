import { getDomainID, qaBackendApiInstance } from '../../config/config'
import { getElementIdJsonPage } from './findReplicaJson'


export function generateUniqueId() {
  return Math.random().toString(36).substr(2, 10)
}

export const handlePutImage = async (projectID: any, store: any) => {
  const blob = (await store.toBlob({ mimeType: 'image/jpeg' })) as any
  const file = new File([blob], 'preview.jpg')
  const formData = new FormData()
  formData.append('file', file)
  const imageUpload = await qaBackendApiInstance.post(`/smp/save-ad-image/${projectID}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export async function updateProject(userDetails: any, store: any, scriptDetails: any): Promise<any> {
  try {
 
    const payload = userDetails
    payload.json_body = store.toJSON()
    payload.script = scriptDetails
    payload.first_render = false
    const domainID = getDomainID() || ''
    const response = await qaBackendApiInstance.put(
      `/smp/${userDetails.id}?domain_id=${encodeURIComponent(domainID)}`,
      { ...payload }
    )
  } catch (error) {
    console.error('Error updating project:', error)
    // message.error('Project could not be updated.')
  }
}

export function calculateYPosition(canvasHeight: number, textBoxHeight: number, paddingFromBottomPercentage: number) {
  return canvasHeight - textBoxHeight - canvasHeight * (paddingFromBottomPercentage / 100)
}

export function capitalizeWords(str: any) {
  return str.replace(/\b\w/g, function (char: any) {
    return char.toUpperCase()
  })
}

export function setCookie({ name, value, days, domain }: any) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/; domain=' + domain
}
