interface Position {
    x: number;
    y: number;
    width: number;
    height: number;
  }
  
  /**
   * Calculate position for placing a video/image at the bottom-left of a container.
   * @param url - The URL of the video or image.
   * @param containerWidth - The width of the container.
   * @param containerHeight - The height of the container.
   * @returns A Promise resolving to the position object with `x`, `y`, `width`, and `height`.
   */
  export async function calculateBottomLeftPosition(
    url: string,
    containerWidth: number,
    containerHeight: number,
    type:string
  ): Promise<Position> {
    return new Promise((resolve, reject) => {
    //   const isVideo = url.endsWith(".mp4") || url.endsWith(".webm") || url.endsWith(".ogg");
  
      if (type==='video') {
        const video = document.createElement("video");
        video.src = url;
  
        video.onloadedmetadata = () => {
          const videoWidth = video.videoWidth;
          const videoHeight = video.videoHeight;
  
          resolve({
            x: 0,
            y: containerHeight - videoHeight,
            width: videoWidth,
            height: videoHeight,
          });
        };
  
        video.onerror = () => reject(new Error("Failed to load video metadata."));
      } else {
        const img = new Image();
        img.src = url;
  
        img.onload = () => {
          const imageWidth = img.naturalWidth;
          const imageHeight = img.naturalHeight;
  
          resolve({
            x: 0,
            y: containerHeight - imageHeight,
            width: imageWidth,
            height: imageHeight,
          });
        };
  
        img.onerror = () => reject(new Error("Failed to load image."));
      }
    });
  }
  