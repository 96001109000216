import React from "react";
import { Spin } from "antd";

interface FullScreenLoaderProps {
  loading: boolean;
}

const FullScreenLoader: React.FC<FullScreenLoaderProps> = ({ loading }) => {
  if (!loading) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(40px)",
        backgroundColor: "#00000099",
        zIndex: 9999,
      }}
    >
      <div style={{display:'flex', justifyContent:'center', flexDirection:'column', alignContent:'center', alignItems:'center'}}>
        <img src="/Loadergif.gif" width={"110px"} height={"110px"} />
        <p style={{color:'white'}}>
          Please wait while we generate the AI avatar. This may take some time.
        </p>
      </div>
    </div>
  );
};

export default FullScreenLoader;
