import React, { useEffect, useState } from "react";
import { Card, Col, Row, Carousel, Radio, type RadioChangeEvent, Button, message, Result, Modal, Popconfirm } from "antd";
import { VideoPlayerCustom } from "../customVideoPlayer/CustomVideoPlayer";
import TextArea from "antd/es/input/TextArea";
import './singleCardBoard.less'
import { useGlobalContext } from "@src/context/globalContext";
import { CheckCircleOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { getDomainID, getToken } from "@src/config/config";
import { aiTextToSpeech, generateCaptions, getAudioDurationInSecondsCustom, getPrevNextText } from "@src/shared/utils/VideoGeneration/audioGeneration";
import { getAudioDurationInSeconds } from "@remotion/media-utils";
import { prefetchAsset } from "@src/shared/utils/remotion";
import { VoiceFilterModalIndividual } from "./voiceFilterIndividual";
import { creditsDeduct, getUserDetails } from "@src/shared/utils/VideoGeneration/helperFunctions/mediaSearch";
import { SelectCustomSearchModal } from "./customMediaSearch";
import { generateUniqueId } from "@src/shared/utils/core";
import { animationFuncCustomeOne } from "@src/shared/utils/VideoGeneration/helperFunctions/animations";
import { fetchReplicas } from "@src/shared/utils/VideoGeneration/helperFunctions/tavusApi";
import { observer } from "mobx-react-lite";
import { ReplicaClonedVoices } from "@src/data/avatarClonedVoices";
import { openAIVoicesList } from "@src/data/music";

type TabPosition = "avatar" | "avatarWithBg" | "noAvatar";
type TabPosition_ = "native" | "custom";

const SingleCardStoryBoard = ({ store, script, scriptIndex, aiVoice }: any) => {
  

  const [tabPositionVoice, setTabPositionVoice] = useState<TabPosition_>(
    "native"
  );
  const [tabPositionAvatar, setTabPositionAvatar] = useState<TabPosition>(
    "avatar"
  );
  const [voicesOpen, setVoicesOpen] = useState(false);
  const [ScriptElements, setScriptElements] = useState<any>([])
  const [textUpdated, setTextUpdated] = useState(false)
  const [generatingVoice, setGeneratingVoice] = useState(false)
  const [mediaVisible, setMediaVisible] = useState(false)
  const [warningVisible, setWarningVisible] = useState(false);
  const [nativeGenerating, setNativegenerating] = useState(false)
  const [activeTabString, setActiveTabString]= useState('')
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    VideoStyle: [videoStyle, setVideoStyle],
    UserDetail: [userDetail, setUserDetail]
  }= useGlobalContext()
  const [scriptVoiceOver, setScriptVoiceOver] = useState(scriptDetails?.data[scriptIndex]?.voiceOver || '')

  const changeTabPositionVoice = async (e: RadioChangeEvent) => {
    setTabPositionVoice(e.target.value);
    if(e.target.value == 'custom'){
        setVoicesOpen(true)
    }else{
      setNativegenerating(true)
      const VoiceId=ReplicaClonedVoices.find(obj => obj.replica_id === userDetail?.purpose?.avatarReplicaId)
      setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }

        const updatedData = [...prevScript.data]

        updatedData[scriptIndex] = {
          ...updatedData[scriptIndex],
          aiAvatarProps:{
            ...updatedData[scriptIndex].aiAvatarProps,
            scriptAvatar: true,
            elevenLabsAiVoiceId:VoiceId?.voice_id || 'chrgGWJZ0CL6HEuYSyfH'
          }
        }
        return { ...updatedScript, data: updatedData }
      })
     await playVoiceSample(VoiceId?.voice_id || 'chrgGWJZ0CL6HEuYSyfH', "Native")
     setNativegenerating(false)

    }
  };
  const changeTabPositionVoiceOnclick = async (value:any)=>{
    setTabPositionVoice(value);
    if(value == 'custom'){
        setVoicesOpen(true)
    }else{
      setNativegenerating(true)
      const VoiceId=ReplicaClonedVoices.find(obj => obj.replica_id === userDetail?.purpose?.avatarReplicaId)
      setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }

        const updatedData = [...prevScript.data]

        updatedData[scriptIndex] = {
          ...updatedData[scriptIndex],
          aiAvatarProps:{
            ...updatedData[scriptIndex].aiAvatarProps,
            scriptAvatar: true,
            elevenLabsAiVoiceId:VoiceId?.voice_id || 'chrgGWJZ0CL6HEuYSyfH'
          }
        }
        return { ...updatedScript, data: updatedData }
      })
     await playVoiceSample(VoiceId?.voice_id || 'chrgGWJZ0CL6HEuYSyfH', "Native")
     setNativegenerating(false)

    }
  }
  const changeTabPositionAvatar = (e: RadioChangeEvent) => {
    
    
    if(e.target.value == 'noAvatar'){
      setMediaVisible(true)
      setActiveTabString('noAvatar')
    
    }else if(e.target.value == 'avatar'){
      setWarningVisible(true)
      setActiveTabString('avatar')
    }else if(e.target.value == 'avatarWithBg'){
      setMediaVisible(true)
      setActiveTabString('avatarWithBg')
    
      // handleBackGroundAvatar(null)
  }
  };
  const changeTabPositionAvatarOnclick = (value:any) => {
    
    // setTabPositionAvatar(value);
    if(value == 'noAvatar'){
      setMediaVisible(true)
      setActiveTabString('noAvatar')
    }else if(value == 'avatar'){
      setWarningVisible(true)
      setActiveTabString('avatar')
    }else if(value == 'avatarWithBg'){
      setMediaVisible(true)
      setActiveTabString('avatarWithBg')
      // handleBackGroundAvatar(null)
  }
  };
const handleDeleteScene=(pageId:string)=>{
  const pagesAssociated:any=[]
  store.pages.map((page:any, index:any)=>{
    
    if(page.custom.scriptIndex == scriptIndex){
      pagesAssociated.push(page.id)
    }
  })

  if (pagesAssociated.length<2){
    message.warning('Scene must have atleast one media.')
    return
  }
  const filteredElements = ScriptElements.filter((element:any) => element.pageId !== pageId);
  store.deleteElements([pageId])
  setScriptElements(filteredElements)
  durationCalculation()
  setScriptDetails((prevScript: { data: any }) => {
    const updatedScript = { ...prevScript }

    const updatedData = [...prevScript.data]
    const pages = store.toJSON().pages;
    const totalPages = pages
    .filter((page: any) => page?.custom?.scriptIndex === scriptIndex)
    .map((page: any) => page.id);
    const images=[]
    for (let i = 0; i < totalPages.length; i++) {
      images.push({searchQueries:[''], duration:0.9})
    }
    updatedData[scriptIndex] = {
      ...updatedData[scriptIndex],
      images:images
    }
    return { ...updatedScript, data: updatedData }
  })
  // console.log(store.toJSON())
  
}
// useEffect(()=>{
// console.log(store)
// },[store])
const handleAdd=(item:any)=>{
  console.log(activeTabString)

  if(activeTabString=='noAvatar'){
    console.log('hello')
   setTabPositionAvatar('noAvatar');
    addPageScene(item)
    setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }

        const updatedData = [...prevScript.data]

        updatedData[scriptIndex] = {
          ...updatedData[scriptIndex],
          aiAvatarProps:{
            ...updatedData[scriptIndex].aiAvatarProps,
            noAvatar:true,
            avatarWithBg:false,
            avatar:false,
          }
         
        }

        return { ...updatedScript, data: updatedData }
      })
  }else if(activeTabString=='avatarWithBg'){
   setTabPositionAvatar('avatarWithBg');

  handleBackGroundAvatar(item)
   setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }

        const updatedData = [...prevScript.data]

        updatedData[scriptIndex] = {
          ...updatedData[scriptIndex],
          aiAvatarProps:{
            ...updatedData[scriptIndex].aiAvatarProps,
            noAvatar:false,
            avatarWithBg:true,
            avatar:false,
          }
         
        }

        return { ...updatedScript, data: updatedData }
      })
  }
}
const handleBackGroundAvatar=async(item:any)=>{
 console.log('hello')
  const pageId=ScriptElements[ScriptElements.length-1].pageId
  const selectedpage=store.getElementById(pageId)
  const prevChildSrc:any=[]
  selectedpage.children.map((child:any)=>{
   
    if(child.type=='video' || child.type =='image'){
      const obj={src:child.src, type:child.type}
      prevChildSrc.push(obj)
      store.deleteElements([child.id]);
    }
  })
  
  const ReplicasTavus = await fetchReplicas({
    limit: 200,
    page: 1,
    verbose: true,
    // replica_type: "",
    replica_ids: "",
  });

  // const initialAvatarUrl = ReplicasTavus.find(
  //   (replica: any) =>
  //     replica.replica_id == userDetail?.purpose?.avatarReplicaId || userDetail?.purpose?.size ==  "9:16" ? 'r6d479c214' : 'rf7476ce9f'
  // );
   const initialAvatarUrl = ReplicasTavus.find((replica: any) => {
    const avatarReplicaId = userDetail?.purpose?.avatarReplicaId;
    const sizeCondition = userDetail?.purpose?.size === '9:16';
    
    // Determine the replica ID based on the conditions
    let replicaId = '';
    if(avatarReplicaId){
      replicaId=avatarReplicaId
    }else{
      replicaId = sizeCondition ? 'ra5e60eeb07f' : 'r79e1c033f'
    }
   
    return replica.replica_id === replicaId;
  });
  const videoChildId = generateUniqueId();
  if(item){
    selectedpage.addElement({
      src: item.video_url || item.image_url,
      type: item.type,
      id: videoChildId,
      // duration: pageDuration,
      x: -25,
      y: -25,
      width: store.width + 50,
      height: store.height + 50,
    // mask:true,

      custom: {
        gettyId: videoChildId,
        mainElement: false,
        aiAvatar: false,
        // scriptIndex: index,
      },
    });
  }else{
    selectedpage.addElement({
      src: prevChildSrc[0]?.src,
      type: prevChildSrc[0]?.type,
      id: videoChildId,
      // duration: pageDuration,
      x: -25,
      y: -25,
    // mask:true,

      width: store.width + 50,
      height: store.height + 50,
      custom: {
        gettyId: videoChildId,
        mainElement: false,
        aiAvatar: false,
        // scriptIndex: index,
      },
    });
  }
  
  const videoIdMain = generateUniqueId();
  selectedpage.addElement({
    src: initialAvatarUrl?.thumbnail_video_url || "",
    type: "video",
    id: videoIdMain,
    // duration: pageDuration,
    x: -1,
    y: store.height - store.height / 3,
    width: store.width / 3,
    height: store.height / 3,
    cornerRadius:20,
    borderRadius:10,
    custom: {
      gettyId: videoIdMain,
      mainElement: true,
      aiAvatar: true,
      bg:true
      // scriptIndex: index,
    },
  });
  console.log('hello')
  // store.getElementById(videoIdMain).set({
  //   animations: animationFuncCustomeOne(store.pages[scriptIndex].duration),
  // });
}
const durationCalculation = async () => {
  const audioUrl = scriptDetails?.data?.[scriptIndex]?.url;
  let duration = 2; // Default duration in seconds

  // Calculate audio duration if the URL is available
  if (audioUrl) {
    duration = await getAudioDurationInSecondsCustom(audioUrl);
  }

  const newDuration = duration * 1000; // Convert duration to milliseconds
  const pages = store.toJSON().pages;

  // Filter pages matching the given scriptIndex
  const totalPages = pages
    .filter((page: any) => page?.custom?.scriptIndex === scriptIndex)
    .map((page: any) => page.id);

  const durationPerPage = newDuration / totalPages.length;

  totalPages.forEach((pageId: string) => {
    store.getElementById(pageId).set({ duration: durationPerPage,  animations: animationFuncCustomeOne(durationPerPage) });
  });
};

const addPageScene=(item:any)=>{
console.log('add scene')
  const pageId=ScriptElements[ScriptElements.length-1].pageId
  
  const ClonedPageId:any = generateUniqueId();
  store.getElementById(pageId).clone({ id: ClonedPageId })
  const clonedPage=store.getElementById(ClonedPageId)
 
  clonedPage.children.map((child:any)=>{
   
    if(child.type=='video' || child.type =='image'){
      store.deleteElements([child.id]);
    }
  })
  const elementId = generateUniqueId();

  clonedPage.addElement({
    type:item.type,
    src:item.video_url || item.image_url,
    x: -25,
    y: -25,
    width: store.width + 50,
    height: store.height + 50,
    id: elementId,
    // mask:true,
    custom: {
      gettyId: item.id,
      voiceOver: scriptDetails?.data?.[scriptIndex].voiceOver,
      aiAvatar: false,
    },
    
  })
  const newObj={
    elementId: elementId,
    pageId: ClonedPageId,
    type:item.type,
    src: item.video_url || item.image_url,
    pageIndex:0
  }

  let isAvatar=false
  store.getElementById(pageId).children.map((child:any)=>{
   
    if(child.type=='video' && child?.custom?.aiAvatar){
      store.deleteElements([pageId]);
      isAvatar=true
      
    }
  })
console.log(isAvatar)
  if(isAvatar){
    setScriptElements([newObj])
  }else{
    setScriptElements([...ScriptElements, newObj])
  }
  durationCalculation()
  setScriptDetails((prevScript: { data: any }) => {
    const updatedScript = { ...prevScript }

    const updatedData = [...prevScript.data]
    const pages = store.toJSON().pages;
    const totalPages = pages
    .filter((page: any) => page?.custom?.scriptIndex === scriptIndex)
    .map((page: any) => page.id);
    const images=[]
    for (let i = 0; i < totalPages.length; i++) {
      images.push({searchQueries:[''], duration:0.9})
    }
    updatedData[scriptIndex] = {
      ...updatedData[scriptIndex],
      images:images
    }
    return { ...updatedScript, data: updatedData }
  })
  //  store.getElementById(elementId).set({
  //         animations: animationFuncCustomeOne(pageDuration),
  //       });
  // console.log(lastScenePageIndex)

  
}



const fullAvatarScene=async()=>{
setTabPositionAvatar('avatar');
  setScriptDetails((prevScript: { data: any }) => {
    const updatedScript = { ...prevScript }

    const updatedData = [...prevScript.data]

    updatedData[scriptIndex] = {
      ...updatedData[scriptIndex],
      aiAvatarProps:{
        ...updatedData[scriptIndex].aiAvatarProps,
        noAvatar:false,
        avatarWithBg:false,
        avatar:true,
      }
    }
    return { ...updatedScript, data: updatedData }
  })
  setWarningVisible(false)
  const pageId=ScriptElements[ScriptElements.length-1].pageId
  const selectedpage=store.getElementById(pageId)
  selectedpage.children.map((child:any)=>{
    if(child.type=='video' || child.type =='image'){
      store.deleteElements([child.id]);
    }
  })
  const ReplicasTavus = await fetchReplicas({
    limit: 200,
    page: 1,
    verbose: true,
    // replica_type: "",
    replica_ids: "",
  });

  const initialAvatarUrl = ReplicasTavus.find((replica: any) => {
    const avatarReplicaId = userDetail?.purpose?.avatarReplicaId;
    const sizeCondition = userDetail?.purpose?.size === '9:16';
  
    // Determine the replica ID based on the conditions
    let replicaId = '';
    if(avatarReplicaId){
      replicaId=avatarReplicaId
    }else{
      replicaId = sizeCondition ? 'ra5e60eeb07f' : 'r79e1c033f'
    }
   
    return replica.replica_id === replicaId;
  });
  // const initialAvatarUrl = ReplicasTavus.find(
  //   (replica: any) =>
  //     replica.replica_id == userDetail?.purpose?.avatarReplicaId || userDetail?.purpose?.size ==  "9:16" ? 'ra5e60eeb07f' : 'r79e1c033f'
  // );

  // ReplicasTavus.map((replica:any)=>{
  //   if(replica.replica_id == (userDetail?.purpose?.avatarReplicaId || userDetail?.purpose?.size ==  "9:16" ? 'ra5e60eeb07f' : 'r79e1c033f')){
  //     console.log(replica)
  //   }
  // })
  // const initialAvatarUrll = ReplicasTavus.find{
  //   (replica: any) =>
  //     replica.replica_id == userDetail?.purpose?.avatarReplicaId || userDetail?.purpose?.size ==  "9:16" ? 'ra5e60eeb07f' : 'r79e1c033f'
  // };

  const elementId = generateUniqueId();
 
  selectedpage.addElement({
    src: initialAvatarUrl?.thumbnail_video_url || "",
    type: "video",
    id: elementId,
    // duration: pageDuration,
    x: -25,
    y: -25,
    width: store.width + 50,
    height: store.height + 50,
    cornerRadius:20,
    // mask:true,

    custom: {
      gettyId: elementId,
      mainElement: false,
      aiAvatar: true,
      scriptIndex: scriptIndex,
    },
  });
  setScriptElements([{elementId:elementId, pageId:pageId, type:'video', src:initialAvatarUrl?.thumbnail_video_url || "",pageIndex:scriptIndex}])
  durationCalculation()
}
  useEffect(()=>{
  
    if(scriptDetails.data[scriptIndex]?.aiAvatarProps){
      if(scriptDetails.data[scriptIndex]?.aiAvatarProps?.noAvatar){
        setTabPositionAvatar('noAvatar')
    }else if(scriptDetails.data[scriptIndex]?.aiAvatarProps?.avatarWithBg || scriptDetails.data[scriptIndex]?.aiAvatarProps?.avatarWithWebsite){
        setTabPositionAvatar('avatarWithBg')

    }else if(scriptDetails.data[scriptIndex]?.aiAvatarProps?.avatar){
        setTabPositionAvatar('avatar')
    }
    if(scriptDetails.data[scriptIndex]?.aiAvatarProps?.scriptAvatar){
      setTabPositionVoice('native')
  }else{
      setTabPositionVoice('custom')

  }
    }else{
      setTabPositionAvatar('noAvatar')

      setTabPositionVoice('custom')

    }
   
   
    
  },[scriptDetails])
//   const ScriptElements: any = [];
useEffect(() => {
  
    const scriptElements: any[] = [];
   
    const pages = store.toJSON().pages;
    pages.forEach((page: any,pageIndex:number) => {

      if(page?.custom?.scriptIndex !== undefined && page?.custom?.scriptIndex !== null){
        if (page?.custom?.scriptIndex === scriptIndex) {
  
          // Find the first matching child and process it
          const matchingChild = page.children?.find(
            (child: any) => child.type === "video" || child.type === "image"
          );
    
          if (matchingChild) {
            scriptElements.push({
              elementId: matchingChild.id,
              pageId: page.id,
              type: matchingChild.type,
              src: matchingChild.src,
              pageIndex:pageIndex
            });
          }
        }
      }else if(pageIndex==scriptIndex){
 
        const matchingChild = page.children?.find(
          (child: any) => child.type === "video" || child.type === "image"
        );
  
        if (matchingChild) {
          scriptElements.push({
            elementId: matchingChild.id,
            pageId: page.id,
            type: matchingChild.type,
            src: matchingChild.src,
            pageIndex:pageIndex
          });
        }
      }
    

    });

    // Batch update state once at the end
    setScriptElements((prev: any) => [...prev, ...scriptElements]);
  }, [store, scriptIndex]); // Add appropriate dependencies
  

const playVoiceSample = async (voice_Id:string, aiVoiceName:string)=>{
    setGeneratingVoice(true)
    const getCredits = await getUserDetails();
    if (getCredits?.voice_limit < 1) {
        message.warning(
          'Voice credits have been exhausted. Please upgrade the plan to use the voice.'
        );
        setGeneratingVoice(false)

  
        return;
      }
    videoStyle !== 'Burst' && store.selectPage(store.pages[scriptIndex].id)
    const domainId = getDomainID()
    const token = getToken()
    const isOpenAIVoice = openAIVoicesList.includes(
      voice_Id
    );
    let isElevenLabFromUpfront=true
    if(isOpenAIVoice){
      isElevenLabFromUpfront=false
    }
    const Prevnext=getPrevNextText(scriptDetails.data, scriptIndex)
    const audioUrl = await aiTextToSpeech(scriptVoiceOver, voice_Id, domainId, aiVoiceName, isElevenLabFromUpfront, token, Prevnext.prevText, Prevnext.nextText, 50, 75)
    const duration = await getAudioDurationInSeconds(audioUrl)
    let newDuration
   
    if (videoStyle !== 'Burst') {
      
        newDuration =
          duration * 1000 > store.pages[scriptIndex].duration ? duration * 1000 + 500 : store.pages[scriptIndex].duration + 500
        if (scriptIndex === store.pages.length - 1) {
          newDuration = newDuration + 1000
        }
      
        const activePage = store.getElementById(ScriptElements[0]?.pageId);
        activePage.set(
            { duration: newDuration }
        )
      }else{
        console.log(duration)
        const newDuration = duration * 1000; // Convert duration to milliseconds
        console.log(newDuration)
        const pages = store.toJSON().pages;
        const totalPages = pages
    .filter((page: any) => page?.custom?.scriptIndex === scriptIndex)
    .map((page: any) => page.id);

  const durationPerPage = newDuration / totalPages.length;

  totalPages.forEach((pageId: string) => {
    store.getElementById(pageId).set({ duration: durationPerPage,  animations: animationFuncCustomeOne(durationPerPage) });
  });
      }
      let captionsResult: any
      if(scriptVoiceOver.length>0){
        try {
          captionsResult = await generateCaptions(audioUrl, userDetail?.purpose?.language || '')
         
        } catch (e) {
          message.info('Could not generate captions for the audio')
        }
      }else{
        console.log('empty url')
      }

      await setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }

        const updatedData = [...prevScript.data]

        updatedData[scriptIndex] = {
          ...updatedData[scriptIndex],
          voiceOver:scriptVoiceOver,
          name: aiVoiceName,
          elevenLabsAiVoiceId: voice_Id,
          url: audioUrl,
          isElevenApi: true,
        //   aiVoiceVolume: voiceOverVolume / 100,
          sounds: [{ url: audioUrl, audioDuration: duration }],
          captions: [{ text: scriptVoiceOver, words: captionsResult?.data?.words }],
        }

        return { ...updatedScript, data: updatedData }
      })
      if (videoStyle !== 'Burst') {
        store.pages[scriptIndex].set({
          duration: newDuration,
          custom: {
            ...store.pages[scriptIndex].custom,
            // aiVoiceVolume: voiceOverVolume / 100,
            sounds: [{ url: audioUrl, audioDuration: duration }],
            captions: [{ text: scriptVoiceOver, words: captionsResult.data.words }],
          },
        })
      }
      await prefetchAsset(audioUrl)
      const audioDurationInMins = Math.ceil(duration / 60);
      creditsDeduct('voice_credits', audioDurationInMins);
      message.info('Voice changed successfully."')
    setGeneratingVoice(false)
}
const handleChangeVoice=(voice_id:string, voiceName:string, similarity: number,
    stability: number)=>{
    
    playVoiceSample(voice_id, voiceName)
}
  return (
    <Card style={{ width:"300px", borderRadius: "10px"}}>
        {(ScriptElements.length>0 && ScriptElements.length<2 )? (
      //      <div key={ScriptElements[0].elementId}>
      //      {ScriptElements[0].type === "video" ? (
      //        <div style={{ position: 'relative', display: 'inline-block' }}>
      //          <VideoPlayerCustom src={ScriptElements[0].src} />
      //          <DeleteOutlined
      //   style={{
      //     position: 'absolute',
      //     bottom: '10px',
      //     right: '10px',
      //     fontSize: '20px',
      //     color: 'red',
      //     cursor: 'pointer',
      //   }}
      //   onClick={() => handleDeleteScene(ScriptElements[0].pageId)}
      // />
      //        </div>
      //      ) : ScriptElements[0].type === "image" ? (
      //        <div style={{ position: 'relative', display: 'inline-block' }}>
      //          <img
      //            src={ScriptElements[0].src}
      //            alt="carousel-item"
      //            style={{ width: '100%', maxHeight: '40vh' }}
      //          />
      //         <DeleteOutlined
      //   style={{
      //     position: 'absolute',
      //     bottom: '10px',
      //     right: '10px',
      //     fontSize: '20px',
      //     color: 'red',
      //     cursor: 'pointer',
      //   }}
      //   onClick={() => handleDeleteScene(ScriptElements[0].pageId)}
      // />
      //        </div>
      //      ) : null}
      //    </div>
      <div key={ScriptElements[0].elementId} style={{display:'flex', justifyContent:'center'}}> 
      {ScriptElements[0].type === "video" ? (
       <div style={{ position: 'relative', display: 'inline-block' }}>
         <VideoPlayerCustom src={ScriptElements[0].src} />
         <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteScene(ScriptElements[0].pageId)}>
         <DeleteOutlined
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          fontSize: '20px',
          color: 'red',
          cursor: 'pointer',
        }}
        // onClick={() => handleDeleteScene(ScriptElements[0].pageId)}
      />
 </Popconfirm>
       </div>
      
      ) : ScriptElements[0].type === "image" ? (
        <div style={{ position: 'relative', display: 'inline-block' }}>
        <img src={ScriptElements[0].src} alt="carousel-item" style={{ width: "100%",  maxHeight: '40vh' }} />
        <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteScene(ScriptElements[0].pageId)}>
        <DeleteOutlined
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          fontSize: '20px',
          color: 'red',
          cursor: 'pointer',
        }}
        // onClick={() => handleDeleteScene(ScriptElements[0].pageId)}
      />
      </Popconfirm>
        </div>
      ) : null}
    </div>
        ):(
            <Carousel effect="fade" >
            {ScriptElements.map((element: any) => (
              <div key={element.elementId} style={{display:'flex', justifyContent:'center'}}>
                {element.type === "video" ? (
                  <div style={{ position: 'relative', display: 'inline-block' }}>

                  <VideoPlayerCustom src={element.src} />
                  <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteScene(element.pageId)}>
                  <DeleteOutlined
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          fontSize: '20px',
          color: 'red',
          cursor: 'pointer',
        }}
        // onClick={() => handleDeleteScene(element.pageId)}
      />
      </Popconfirm>
                  </div>
                ) : element.type === "image" ? (
                  <div style={{ position: 'relative', display: 'inline-block' }}>

                  <img src={element.src} alt="carousel-item" style={{ width: "100%",  maxHeight: '40vh'  }} />
                  <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteScene(element.pageId)}>
                  <DeleteOutlined
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          fontSize: '20px',
          color: 'red',
          cursor: 'pointer',
        }}
        // onClick={() => handleDeleteScene(element.pageId)}
      />
      </Popconfirm>
                </div>
                ) : null}
              </div>
            ))}
          </Carousel>
        )}
   

<div style={{display:'flex', flexDirection:'column', gap:'4px', marginTop:'35px'}}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p>{scriptDetails?.data?.[scriptIndex]?.adBlockType}</p>
        <Radio.Group value={tabPositionVoice} onChange={changeTabPositionVoice}>
          <Radio.Button value="native" style={{
             backgroundColor: tabPositionVoice === "native" ? "var(--color-primary-500)" : '',
             color:tabPositionVoice === "native"?'white':"var(--color-primary-500)" 
          }}
          onClick={()=>changeTabPositionVoiceOnclick('native')}>{nativeGenerating ? <LoadingOutlined/> : 'Native'}</Radio.Button>
          <Radio.Button value="custom" style={{
             backgroundColor: tabPositionVoice === "custom" ? "var(--color-primary-500)" : '',
             color:tabPositionVoice === "custom"?'white':"var(--color-primary-500)" 
          }} 
          onClick={()=>changeTabPositionVoiceOnclick('custom')}>Custom</Radio.Button>
        </Radio.Group>
      </div>
      <div style={{ display: "flex", justifyContent: "start", gap:'4px' }}>
        <Radio.Group
          value={tabPositionAvatar}
          onChange={changeTabPositionAvatar}
        >
          <Radio.Button value="avatar" style={{ fontSize: '12px',
                backgroundColor: tabPositionAvatar === "avatar" ? "var(--color-primary-500)" : '',
                color:tabPositionAvatar === "avatar"?'white':"var(--color-primary-500)" 

           }}
           onClick={()=>changeTabPositionAvatarOnclick('avatar')}>Full Avatar</Radio.Button>
          <Radio.Button value="avatarWithBg" style={{ fontSize: '12px',
             backgroundColor: tabPositionAvatar === "avatarWithBg" ? "var(--color-primary-500)" : "",
                                color:tabPositionAvatar === "avatarWithBg"?'white':"var(--color-primary-500)" 

           }}
           onClick={()=>changeTabPositionAvatarOnclick('avatarWithBg')}>Avatar with Media</Radio.Button>
          <Radio.Button value="noAvatar" style={{ fontSize: '12px',
             backgroundColor: tabPositionAvatar === "noAvatar" ? "var(--color-primary-500)" : "",
                                color:tabPositionAvatar === "noAvatar"?'white':"var(--color-primary-500)" 

           }}
           onClick={()=>changeTabPositionAvatarOnclick('noAvatar')}>Custom</Radio.Button>
        </Radio.Group>
      </div>
     
      <div style={{ position: 'relative', width: '100%' }}>
  <TextArea
    placeholder="Enter the voice-over for the scene."
    value={scriptVoiceOver}
    
    style={{ width: '100%' }}
    onChange={(e) => {
       if(scriptVoiceOver !== scriptDetails?.data[scriptIndex?.voiceOver]){
        setTextUpdated(true)

       }
      setScriptVoiceOver(e.target.value)

      }}

  />
  {textUpdated && (
  <Button
  style={{
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    background: 'var(--color-primary-500)',
    color: 'white',
    borderColor: 'transparent',
  }}
  icon={generatingVoice ? <LoadingOutlined /> : <CheckCircleOutlined />}
  disabled={generatingVoice}
  onClick={()=>playVoiceSample(scriptDetails.data[scriptIndex]?.elevenLabsAiVoiceId, scriptDetails.data[scriptIndex]?.name)}
/>
  )}

</div>

</div>
<VoiceFilterModalIndividual
aiVoice={aiVoice || []}
handleChangeVoice={handleChangeVoice}
selectedVoiceName={scriptDetails?.data[scriptIndex]?.elevenLabsAiVoiceId}
voicesOpen={voicesOpen}
setVoicesOpen={setVoicesOpen}
/>
<SelectCustomSearchModal
visible={mediaVisible}
setVisible={setMediaVisible}
store={store}
index={scriptIndex}
searchQueries={[]}
callBack={handleAdd}
/>
<WarningModal
warningVisible={warningVisible}
setWarningVisible={setWarningVisible}
callBackUrl={fullAvatarScene}
/>
    </Card>
  );
};

export const WarningModal=({
  warningVisible,
  setWarningVisible,
callBackUrl
}:any)=>{

  return(
    <Modal
    open={warningVisible}
    onOk={callBackUrl}
    onCancel={()=>setWarningVisible(false)}
    >
      
    {/* <div
    style={{
      padding: "20px",
      maxWidth: "300px",
      width: "90%",
      background: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      zIndex: 1001,
      translate: "enter fade 1s ease",
      
    }}
  > */}
      <Result
          status="info"
          subTitle="Are you sure you want to make this a full avatar scene?"
          style={{
            color: "var(--color-primary-600)",
            fontSize: "16px",
            fontWeight: 500,
            letterSpacing: "normal",
            marginBottom: "10px",
          }}
          // extra={[
          //   <Button
          //     style={{ minWidth: "120px", marginBottom: "10px" }}
          //     type="primary"
          //     key="goNewProject"
          //     onClick={callBackUrl}
          //   >
          //     Add
          //   </Button>,
           
          //   <Button style={{ minWidth: "120px" }} key="stay" onClick={()=>setWarningVisible(false)}>
          //     Exit
          //   </Button>,
          // ]}
        />
  {/* </div> */}
  </Modal>

  )
}

export default observer(SingleCardStoryBoard);
