export const ReplicaClonedVoices=[
    { "replica_id": "rce846fcf7", "voice_id": "QJ29mjvvsUt5Zuq3TfOV" },
    { "replica_id": "rc5ab5fe34", "voice_id": "iCYuvM1Jn7PeFPETNeWI" },
    { "replica_id": "r250b2003c", "voice_id": "yoKFRlq4mlZxChRDbJC3" },
    { "replica_id": "r10e648bfa", "voice_id": "TAy8RVfAJV4cE2UqMGjZ" },
    { "replica_id": "r18d46c93e", "voice_id": "8VVBZtbMBGvJ25sEAPqX" },
    { "replica_id": "r1b15de50c", "voice_id": "VXksPXSbv9ycaqcmhNh7" },
    { "replica_id": "r89329f4fd", "voice_id": "rvxKdPLTSp04ciU2FulW" },
    { "replica_id": "r4da784871", "voice_id": "x8uDDChWwX4YWfvTjgoa" },
    { "replica_id": "r315bff4de", "voice_id": "x2pzYyietdHYYJAmPfPo" },
    { "replica_id": "rc46b5d772", "voice_id": "chrgGWJZ0CL6HEuYSyfH" },
    { "replica_id": "r74c734ddc", "voice_id": "DLfQug0YvHAghZhD3yvx" },
    { "replica_id": "rf725707a5", "voice_id": "QoARSBTqvIvCgLFHl3rL" },
    { "replica_id": "r93bd4e488", "voice_id": "0G32PfJIMmIhRm1v8O8S" },
    { "replica_id": "r70f15c5b1", "voice_id": "PYjLgbxy8ubPcpCCLV5r" },
    { "replica_id": "r934c980b4", "voice_id": "CJfYLASPGch7Jh5f3tgj" },
    { "replica_id": "r694955199", "voice_id": "XpLRUZrcMTsDazFdoeQI" },
    { "replica_id": "r1a667ea75", "voice_id": "i9peqJeTnzFsXZb6oQHE" },
    { "replica_id": "r2b021a590", "voice_id": "PpfjuA9miEDKh2xiC7Sy" },
    { "replica_id": "r2bdbdd931", "voice_id": "UtK8lVOeXTWhDlkswMeN" },
    { "replica_id": "r054afacf7", "voice_id": "ARTu9bqSRN6puEGv1Wc9" },
    { "replica_id": "r594c6a4aa", "voice_id": "XLYiqXjm89VLXxcBHsk2" },
    { "replica_id": "r49f0f6270", "voice_id": "v63y6LIIlULayQw6hq4o" },
    { "replica_id": "rb508541fb", "voice_id": "GAEjT2lRJBQniMKUK7Sw" },
    { "replica_id": "rbedfcceb3", "voice_id": "OO2msJrSwI95c99BLj2x" },
    { "replica_id": "r9d2a7a53c", "voice_id": "HhXTdY8srPVWIgMwZIUm" },
    { "replica_id": "r2731127d5", "voice_id": "iOVANcNAL1ouZfHyILdF" },
    { "replica_id": "r41c3031c1", "voice_id": "dryWRxvnOFZf63720Jpd" },
    { "replica_id": "r43f5154d6", "voice_id": "yfa2q0hJX8oZGjBkOZqM" },
    { "replica_id": "r1e69411f2", "voice_id": "KCJw0YaFyLHeBzcuAqOa" },
    { "replica_id": "r336b27aff", "voice_id": "oYYnidVxkfLK51CFSzc1" },
    { "replica_id": "r4c13091db", "voice_id": "nXqnkRoH9Kxz5vEtXcZz" },
    { "replica_id": "rde3b1a18f", "voice_id": "kCyfd6Z0HsVUTSBSPiUq" },
    { "replica_id": "rf8142b7f0", "voice_id": "7G2pqJ73antTngl3AMAw" },
    { "replica_id": "ra0322b20e", "voice_id": "2KeD5CBcHzQD9KcmlQz4" },
    { "replica_id": "r86661906c", "voice_id": "mCFtpv03My2HnIM0LEio" },
    { "replica_id": "r094ecc9a5", "voice_id": "BKhpaJSW3bukou3YFYfm" },
    { "replica_id": "rfb51183fe", "voice_id": "zKHAu0L23C6yBCNb2aJm" },
    { "replica_id": "r8bb0646ae", "voice_id": "C3C3r4Ddxy2RHYvUATlH" },
    { "replica_id": "ra54d1d861", "voice_id": "SDM3h5Cle2NsU9Aye1Os" },
    { "replica_id": "ref226fe7e", "voice_id": "dBU7ATRUMfitty62KFZr" },
    { "replica_id": "rbb0f535dd", "voice_id": "ULVaitS4xMKjaN1lWgZa" },
    { "replica_id": "r6b7318f8d", "voice_id": "XLmRDCuDAZrGFbwlGfYT" },
    { "replica_id": "r767d40a50", "voice_id": "QE2gmxWckCCVTh6PBvCj" },
    { "replica_id": "r1fbfc941b", "voice_id": "Lc6KgXkVhxmfPjqRkaWx" },
    { "replica_id": "r2c64b5071", "voice_id": "BM7gMyWAstCThOT1OPeO" },
    { "replica_id": "r0104286ea", "voice_id": "2R6OHF0M3EQsaEG4K88O" },
    { "replica_id": "rfd03e9ebc", "voice_id": "BjRYkg5ehfnQMIVlLQLD" },
    { "replica_id": "r09d16d4b6", "voice_id": "ccivPXY0H2v6ubdKMpWa" },
    { "replica_id": "re8e740a42", "voice_id": "VW2dJDy0v3QF7oFUJ01B" },
    { "replica_id": "re602baff7", "voice_id": "psX4h8JkgtWOZ0bkPVta" },
    { "replica_id": "r637dd0cdd", "voice_id": "JDAFRaSkEAsRTGO5NTld" },
    { "replica_id": "r6f41becb2", "voice_id": "Bb3miUbPaxU2mMVWSZdV" },
    { "replica_id": "r8e839ebb6", "voice_id": "XwwDU9d3tvk3A5WkUbLY" },
    { "replica_id": "rca5e9b9dc", "voice_id": "xH73wPwFaJkisnjOTpCy" },
    { "replica_id": "r7dbef2aab", "voice_id": "Frn0bJznX8qQykQkNgU9" },
    { "replica_id": "r4e34d2d67", "voice_id": "Xqvbbpg53xuVkOI95C56" },
    { "replica_id": "r6d479c214", "voice_id": "At35WLXw3njtwsud0PAz" },
    { "replica_id": "r40f2da1a2", "voice_id": "JkTcN9hgYjL7pbCao5cN" },
    { "replica_id": "rb8a2b77cb", "voice_id": "dozKubFr9ajIFJTREVpd" },
    { "replica_id": "r105cfc41a", "voice_id": "3yMOa0qUjmRvx2mAkGb8" },
    { "replica_id": "r5c5d0370b", "voice_id": "cfsPTlssCw2zfdh1MJHW" },
    { "replica_id": "r243eed46c", "voice_id": "LQKt7cTUltzlB6SlBV0C" },
    { "replica_id": "r445a7952e", "voice_id": "1cLGdg4XGpOlE6Xi0uet" },
    { "replica_id": "r3b3c4f911", "voice_id": "kaBzPLBWUYbK31wZT2BA" },
    { "replica_id": "r660c4f3ba", "voice_id": "lMOrTqYSHAjtYVebMCvP" },
    { "replica_id": "r9247bcb15", "voice_id": "G5xzYORgYP3ZQQ8mtbFs" },
    { "replica_id": "r8bfa69a42", "voice_id": "DHx5BI4dTSQZ1n33Ckpa" },
    { "replica_id": "r084238898", "voice_id": "plOjsdgRcmO6nm9F7DVI" },
    { "replica_id": "r40ee7e29b", "voice_id": "DY2KmlGZ3VrYdAGqUDNb" },
    { "replica_id": "r51cb5ea70", "voice_id": "aWnVo3UbS4VMCvGMxZ6T" },
    { "replica_id": "rbff6415e6", "voice_id": "6UPndkQ9Lon84vyXwsHA" },
    { "replica_id": "r5b3265ee1", "voice_id": "fstfTTU8rtrdQyTluiS3" },
    { "replica_id": "r4e401136b", "voice_id": "puv5gTdYRKF34yE1rFfr" },
    { "replica_id": "r654493d9e", "voice_id": "IOG9kr2bVNUsJ0ggbijF" },
    { "replica_id": "r9191662c8", "voice_id": "KUuuQcOXtCfdy18BGHUH" },
    { "replica_id": "r604520422", "voice_id": "95e8sU1RdCCdFIy5qr6c" },
    { "replica_id": "r3a5283365", "voice_id": "kd7cM7nLR4FhvnkXYEJc" },
    { "replica_id": "rd4e6981aa", "voice_id": "42fzPdLj5rvSliQMqGUo" },
    { "replica_id": "r43d31a795", "voice_id": "hRJ5so8EkFEF7XmWOHVv" },
    { "replica_id": "r4f6470a95", "voice_id": "dz6Aic7z7RPvomDGBK1o" },
    { "replica_id": "r590584193", "voice_id": "eviy6t2FTQRykUFP9LgE" },
    { "replica_id": "r74df1ac87", "voice_id": "8lVy2dAOZuwzOchgw6Hm" },
    { "replica_id": "r4c41453d2", "voice_id": "cOafgZtQv9xFTkFj7kyK" },
    { "replica_id": "r6c3cc3111", "voice_id": "SZOKl5iynJ34aaqAr40Q" },
    { "replica_id": "ref9611c10", "voice_id": "KDeg2X7Mof0dO3jDNGyl" },
    { "replica_id": "r393c989e2", "voice_id": "wYoda4FaZx7PTYF4thjk" },
    { "replica_id": "r5a10b33be", "voice_id": "Sp8n1QY57T5CLedJKW1C" },
    { "replica_id": "rab1105513", "voice_id": "387nX2gQBo6CLDUWnADt" },
    { "replica_id": "r87e8afa7d", "voice_id": "yePynaw9NgN1IZ17Nt9s" },
    { "replica_id": "r4249d7154", "voice_id": "ftMGg20gRn1r2AD5uHcV" },
    { "replica_id": "rceacec30f", "voice_id": "AyAJsOpxNthsUgpShtkQ" },
    { "replica_id": "r067b795cc", "voice_id": "lU1BrKZ1TVVT7zCLkzOS" },
    { "replica_id": "r605415625", "voice_id": "Kpc4V1A2JGKtfDs0Hxrj" },
    { "replica_id": "r63f390d9b", "voice_id": "jllgD8Wx5Rz79qOyMVJG" },
    { "replica_id": "r21027455f", "voice_id": "cGEK0hqn5BtNKkZJLBvj" },
    { "replica_id": "r0f7e90793", "voice_id": "y8yjKPeTpqkrmiT4iNyN" },
    { "replica_id": "re008a6ea6", "voice_id": "rdtVTMElrcJ3wph0DLMx" },
    { "replica_id": "r725beda81", "voice_id": "52mJQxBMQ5cBr8gktY3M" },
    { "replica_id": "r79e1c033f", "voice_id": "JniEsvnpWbemLccpoefh" },
    { "replica_id": "rab615a199", "voice_id": "gFu8C1z4CB7EmBBg5lZA" },
    { "replica_id": "r52da2535a", "voice_id": "1QugTRBbwk01vE2ZRSmA" },
    { "replica_id": "r6583a465c", "voice_id": "NMCiImpVLPclnJCuOqAI" },
    { "replica_id": "rf7476ce9f", "voice_id": "rHjPHZDYKhMx2fNjQP4i" },
    { "replica_id": "rbc674de11", "voice_id": "V32QULBk2Z2Y7MiHTcbr" },
    { "replica_id": "rb3121b3c4", "voice_id": "liYeiAN69bRwBSwJWyKd" },
    { "replica_id": "r9c69e58e2", "voice_id": "424nYgcAIaT6rJGwvcSc" }
  ]
  