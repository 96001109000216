import { IS_FREE_PLATFORM, REDIRECT_ON } from "../../../../config";
import { FONT_TEMPLATE } from "../../../../data/captions";
import { defaultJsonTemplate } from "../../../../data/defaultJsonTemplate";
import { logo, logoCTA } from "../../../../data/logo";
import {
  categorisedMusicList,
  categorisedMusicListTwo,
} from "../../../../data/music";
import { getImageSize } from "polotno/utils/image";
import { prefetch } from "remotion";
import { generateUniqueId } from "../../core";
import { generateAiAudio } from ".././audioGeneration";
import {
  getPexelsVideos,
  getGettyVideos,
  getGettyImages,
  getGettyVideosTest,
  aiImagesAPI,
  generateDalleImage,
} from ".././helperFunctions/mediaSearch";
import {
  animationFunc,
  animationFuncCustomeOne,
  animationFuncCustomeOne3D,
} from ".././helperFunctions/animations";
import { fitTextwithBreaks } from "../../polotno/resizing";
import { generateSubtitles } from "../../captions";
import { generateAiAvatarVideo } from "../helperFunctions/tavusApi";
import { template1 } from "@src/data/aiAvatarTemplates/template1";

export async function AvatarScriptToCanvas(
  store: any,
  scriptData: any,
  industry: any,
  setBackgroundMusic: any,
  setScriptDetails: any,
  setProgressBarPercent: any,
  icon: string,
  selectedSize: string,
  videoMediaType: string,
  color: any,
  userDetails: any,
  avatarTasks: any,
  setAvatarTasks: any
) {
  // Load default Template if free platform
  if (IS_FREE_PLATFORM === "true") {
    store.loadJSON(defaultJsonTemplate, false);
  }
  // Set default durations to 3.5 seconds
  store.loadJSON(defaultJsonTemplate);
  store.pages.map((page: any) => {
    page.set({ duration: 3500 });
  });

  const json = await store.toJSON();
  const scriptPages = scriptData.data;
  let totalImageLength = 0;

  for (const pages of scriptPages) {
    totalImageLength += pages?.burstImages?.length || 1;
  }
  // Make sure there are enough pages
  // for (let i = json.pages.length; i < scriptPages.length; i++) {
  //   store.pages[i % 2].clone();
  // }

  for (let i = store.pages.length; i < totalImageLength; i++) {
    store.pages[i % 2].clone();
  }
  console.log(totalImageLength);
  console.log(store.pages.length);
  const totalIterations = store.pages.length * 2.25; // Assuming each page has 1 iteration for video and 1 for audio generation
  const progressIncrement = 100 / totalIterations;

  let currentProgress = 0;

  setProgressBarPercent(Math.round(currentProgress));
  // let scriptIndex = 0
  let currentStoreIndex = 0;
  for (let index = 0; index < scriptPages.length; index++) {
    const currentLength = store.pages.length;
    let audioReponse;
    let avatarResponse: any;
    if (!REDIRECT_ON) {
      if (scriptPages[index].voiceOver.length > 0) {
        audioReponse = await generateAiAudio(
          scriptPages[index],
          store,
          index,
          setScriptDetails,
          setProgressBarPercent,
          currentProgress,
          progressIncrement,
          currentLength,
          industry,
          "Simple",
          userDetails,
          selectedSize,
          scriptPages
        );
      }
    }
    const children = store.pages[currentStoreIndex].children;

    let searchIndex = 0;
    let searchQuery;
    if (scriptPages[index]?.searchQueries?.length > 0) {
      searchQuery = scriptPages[index]?.searchQueries?.[0]
        ? scriptPages[index].searchQueries[0]
        : scriptPages[scriptPages.length - 1].searchQueries?.[0];
    } else {
      searchQuery = scriptPages[index]?.images?.[0].searchQueries?.[0]
        ? scriptPages[index]?.images?.[0].searchQueries?.[0]
        : scriptPages[scriptPages.length - 1]?.images?.[0].searchQueries?.[0];
    }

    let orientations = selectedSize === "9:16" ? "vertical" : "horizontal";

    let videoElementID = "";

    let passed = false;
    const videoElements = children.filter(
      (child: any) => child.type === "image" || child.type === "video"
    );
    if (videoElements.length > 0) {
      const largestVideo = videoElements.reduce(
        (prev: any, current: any) =>
          prev.width * prev.height > current.width * current.height
            ? prev
            : current,
        videoElements[0]
      );
      videoElementID = largestVideo.id;
    }
    console.log()
    let searchResult = undefined;

    if (videoElementID !== "") {
      if (IS_FREE_PLATFORM === "false" && videoMediaType === "AiAvatar") {
        while (
          !searchResult &&
          searchIndex < scriptPages[index]?.searchQueries?.length
        ) {
          if (searchIndex === scriptPages[index]?.searchQueries?.length - 1) {
            searchIndex = 0;
            orientations =
              orientations === "vertical" ? "horizontal" : "vertical";

            passed = true;
          }
          const gettyVideoURL: any = await getGettyVideosTest(
            searchQuery,
            orientations,
            userDetails?.purpose?.videotechniques || []
          );

          if (gettyVideoURL === -1) {
            searchIndex++;
            searchQuery = scriptPages[index].searchQueries[searchIndex];
          } else {
            const backgroundSourceUrl = scriptPages[index]?.aiAvatarProps
              ?.avatarWithBg
              ? scriptPages[index]?.aiAvatarProps?.mediaUrl || gettyVideoURL.uri
              : "";
            const backgroundWebiste = scriptPages[index]?.aiAvatarProps
              ?.avatarWithWebsite
              ? scriptPages[index]?.aiAvatarProps?.websiteUrl ||
                userDetails?.purpose?.productUrl
              : "";
            avatarResponse = await generateAiAvatarVideo(
              audioReponse?.audioUrl || "",
              userDetails?.purpose?.avatarReplicaId,
              backgroundWebiste,
              backgroundSourceUrl,
              scriptPages[index].voiceOver,
              scriptPages[index]?.aiAvatarProps?.scriptAvatar
            );
            const videoId = avatarResponse?.video_id || generateUniqueId();
            searchResult = gettyVideoURL;

            if (
              scriptPages[index]?.burstImages?.length > 0 &&
              scriptPages[index]?.aiAvatarProps?.noAvatar
            ) {
              let iterationCounter = 0; // Initialize the counter outside the loop

              for (const burstImage of scriptPages[index]?.burstImages || []) {
                const videoId = generateUniqueId();

                const pageDuration =
                  audioReponse?.newDuration /
                  (scriptPages[index]?.burstImages?.length || 1); // Ensure a valid divisor

                store.pages[currentStoreIndex].addElement({
                  src: burstImage.url,
                  type: burstImage.type,
                  id: videoId,
                  duration: pageDuration,
                  width: store.width,
                  height: store.height,
                  custom: {
                    gettyId: burstImage.id,
                    mainElement: true,
                    aiAvatar: true,
                    scriptIndex: index,
                  },
                });
                store.getElementById(videoId).set({
                  animations: animationFuncCustomeOne(
                    store.pages[currentStoreIndex].duration
                  ),
                });
              const childrenBurst = store.pages[currentStoreIndex].children;
              const videoElements = childrenBurst.filter(
                (child: any) => child.type === "image" || child.type === "video"
              );
                let videoElementIDChild = "";

              if (videoElements.length > 0) {
                const largestVideo = videoElements.reduce(
                  (prev: any, current: any) =>
                    prev.width * prev.height > current.width * current.height
                      ? prev
                      : current,
                  videoElements[0]
                );
                videoElementIDChild = largestVideo.id;
              }
                store.deleteElements([videoElementIDChild])

                store.getElementById(videoId).moveBottom()
            
                store.pages[index].set({
                  duration: pageDuration,
                });
                if (
                  iterationCounter !==
                  scriptPages[index]?.burstImages?.length - 1
                ) {
                  currentStoreIndex++;
                }
                iterationCounter++; // Increment the counter at the end of each loop


                const textElements = children.filter(
                  (child: any) => child.type === "text"
                );
                const sortedTextElementsByFont = textElements
                  .filter(() => {
                    return true;
                  })
                  .sort((a: any, b: any) => b.fontSize - a.fontSize);
                if (sortedTextElementsByFont.length > 0) {
                  const element = store.getElementById(sortedTextElementsByFont[0].id);
                  const fontSize = fitTextwithBreaks(
                    element.width,
                    element.height,
                    element.text,
                    element.fontFamily,
                    element.lineHeight,
                    element.fontSize
                  ); // Calculate the new font size
                  await element.set({
                    // text: scriptPages[index].textOverlay,
                    fontSize: fontSize,
                    y: store.height - 300,
                    visible: false,
                  });
                }
          
                currentProgress += progressIncrement;
                setProgressBarPercent(Math.round(currentProgress));
          
                // const imageUrl = URL.createObjectURL(icon)
          
                if (IS_FREE_PLATFORM === "false") {
                  const { waitUntilDone } = prefetch(icon, {
                    method: "blob-url",
                    contentType: "image/png",
                  });
          
                  await waitUntilDone();
          
                  const iconElement = children.filter(
                    (child: any) => child.id === "quickadsLogo" + index.toString()
                  );
          
                  if (iconElement.length === 0) {
                    if (icon) {
                      let { width, height } = await getImageSize(icon);
          
                      const scaleFactor = selectedSize === "9:16" ? 0.4 : 0.2;
                      let scale = Math.min(
                        store.width / width,
                        store.height / height,
                        scaleFactor
                      );
          
                      // Limit width to 250 pixels
                      if (width * scale > 100) {
                        scale *= 100 / (width * scale); // Adjust scale to fit within 250 width
                      }
          
                      // Ensure height is scaled proportionally
                      height *= scale;
                      width *= scale;
                      if (scriptPages.length - 1 === index) {
                        store.pages[currentStoreIndex].addElement({
                          ...logoCTA,
                          id: "cta" + index.toString(),
                          src: `${icon}`,
                          x:
                            selectedSize === "9:16"
                              ? store.width - width - 150
                              : store.width - width - 185,
                          y: store.height - 400,
                          width: selectedSize === "16:9" ? width + 80 : width,
                          height: selectedSize === "16:9" ? height + 50 : height,
                          opacity: userDetails?.purpose?.showLogo ? 1 : 0,
                          visible: userDetails?.purpose?.showLogo,
                        });
                        const element = store.getElementById("cta" + index.toString());
                        // element.set({ x: store.width - width - 150 })
                      } else {
                        store.pages[currentStoreIndex].addElement({
                          ...logo,
                          id: "quickadsLogo" + index.toString(),
                          src: `${icon}`,
                          x: store.width - width - 25,
                          width: width,
                          height: height,
                          opacity: userDetails?.purpose?.showLogo ? 1 : 0,
                          visible: userDetails?.purpose?.showLogo,
                        });
                        const element = store.getElementById(
                          "quickadsLogo" + index.toString()
                        );
                        // element.set({ x: store.width - width - 25 })
                      }
                    }
                  }
                }

              }
            } else {
              store.pages[currentStoreIndex].addElement({
                type: "video",
                src: gettyVideoURL.uri,
                width: store.width,
                height: store.height,
                id: videoId,
                custom: {
                  gettyId: scriptPages[index]?.assets?.[0]?.id
                    ? scriptPages[index].assets[0]?.id
                    : gettyVideoURL.id,
                  mainElement: true,
                  aiAvatar: true,
                },
              });
              store.getElementById(videoId).set({
                animations: animationFuncCustomeOne(
                  store.pages[currentStoreIndex].duration
                ),
              });
              store.getElementById(videoId).moveBottom()

            }

            if (!scriptPages[index]?.aiAvatarProps?.noAvatar) {
              const newTask = {
                elementId: videoId,
                taskStatus: avatarResponse?.status,
                outPutUrl: "",
                videoId: videoId,
                scriptIndex: currentStoreIndex,
                lessThanThreeSec: audioReponse?.avatarVoicelessThanThreeSec,
              };
              setAvatarTasks((prev: any) => [...prev, newTask]);
            }
            store.deleteElements([videoElementID]);
          }
        }
      }
      store.pages[currentStoreIndex].set({
        custom: {
          ...store.pages[currentStoreIndex].custom,
          searchQuery: searchQuery,
          searchQueries: scriptPages[index].searchQueries,
        },
      });
    }

    currentProgress += progressIncrement;
    setProgressBarPercent(Math.round(currentProgress));
    if (!scriptPages[index]?.aiAvatarProps?.noAvatar) {
      const textElements = children.filter(
        (child: any) => child.type === "text"
      );
      const sortedTextElementsByFont = textElements
        .filter(() => {
          return true;
        })
        .sort((a: any, b: any) => b.fontSize - a.fontSize);
      if (sortedTextElementsByFont.length > 0) {
        const element = store.getElementById(sortedTextElementsByFont[0].id);
        const fontSize = fitTextwithBreaks(
          element.width,
          element.height,
          element.text,
          element.fontFamily,
          element.lineHeight,
          element.fontSize
        ); // Calculate the new font size
        await element.set({
          // text: scriptPages[index].textOverlay,
          fontSize: fontSize,
          y: store.height - 300,
          visible: false,
        });
      }

      currentProgress += progressIncrement;
      setProgressBarPercent(Math.round(currentProgress));

      // const imageUrl = URL.createObjectURL(icon)

      if (IS_FREE_PLATFORM === "false") {
        const { waitUntilDone } = prefetch(icon, {
          method: "blob-url",
          contentType: "image/png",
        });

        await waitUntilDone();

        const iconElement = children.filter(
          (child: any) => child.id === "quickadsLogo" + index.toString()
        );

        if (iconElement.length === 0) {
          if (icon) {
            let { width, height } = await getImageSize(icon);

            const scaleFactor = selectedSize === "9:16" ? 0.4 : 0.2;
            let scale = Math.min(
              store.width / width,
              store.height / height,
              scaleFactor
            );

            // Limit width to 250 pixels
            if (width * scale > 100) {
              scale *= 100 / (width * scale); // Adjust scale to fit within 250 width
            }

            // Ensure height is scaled proportionally
            height *= scale;
            width *= scale;
            if (scriptPages.length - 1 === index) {
              store.pages[currentStoreIndex].addElement({
                ...logoCTA,
                id: "cta" + index.toString(),
                src: `${icon}`,
                x:
                  selectedSize === "9:16"
                    ? store.width - width - 150
                    : store.width - width - 185,
                y: store.height - 400,
                width: selectedSize === "16:9" ? width + 80 : width,
                height: selectedSize === "16:9" ? height + 50 : height,
                opacity: userDetails?.purpose?.showLogo ? 1 : 0,
                visible: userDetails?.purpose?.showLogo,
              });
              const element = store.getElementById("cta" + index.toString());
              // element.set({ x: store.width - width - 150 })
            } else {
              store.pages[currentStoreIndex].addElement({
                ...logo,
                id: "quickadsLogo" + index.toString(),
                src: `${icon}`,
                x: store.width - width - 25,
                width: width,
                height: height,
                opacity: userDetails?.purpose?.showLogo ? 1 : 0,
                visible: userDetails?.purpose?.showLogo,
              });
              const element = store.getElementById(
                "quickadsLogo" + index.toString()
              );
              // element.set({ x: store.width - width - 25 })
            }
          }
        }
      }
    }

    currentStoreIndex++;

    // if(!scriptPages[index]?.aiAvatarProps?.noAvatar){
    //   console.log("-----",currentStoreIndex)
    // }
  }

  const duration = 60;
  currentProgress += progressIncrement;

  const thumbnailImagePrompt =
    scriptPages?.[0]?.searchQueries[0] +
      " " +
      scriptPages?.[0]?.searchQueries[1] +
      " " +
      scriptPages?.[0]?.searchQueries[2] || "thumbnail for video";
  const thumbnailImage = await generateDalleImage(
    thumbnailImagePrompt,
    selectedSize === "9:16" ? "vertical" : "horizontal"
  );
  localStorage.setItem("thumbnailImage", thumbnailImage);
  setProgressBarPercent(Math.round(currentProgress));
  let musicUrl = "";
  let musicName = "";
  // Get local storage data
  const style = localStorage.getItem("style") || "Simple";
  const Prompt = localStorage.getItem("Prompt");

  try {
    if (Prompt === "false") {
      if (categorisedMusicListTwo[style]) {
        const musicArrLength =
          categorisedMusicListTwo[style][selectedSize].length;
        const randomIndex = Math.floor(Math.random() * musicArrLength);
        musicUrl =
          categorisedMusicListTwo[style][selectedSize][randomIndex].url;
        musicName =
          categorisedMusicListTwo[style][selectedSize][randomIndex].name;
      }
    } else {
      if (categorisedMusicListTwo["Prompt"]) {
        const musicArrLength =
          categorisedMusicListTwo["Prompt"][selectedSize].length;
        const randomIndex = Math.floor(Math.random() * musicArrLength);
        musicUrl =
          categorisedMusicListTwo["Prompt"][selectedSize][randomIndex].url;
        musicName =
          categorisedMusicListTwo["Prompt"][selectedSize][randomIndex].name;
      }
    }
  } catch (error) {
    const musicArrLength =
      categorisedMusicListTwo["Simple"][selectedSize].length;
    const randomIndex = Math.floor(Math.random() * musicArrLength);
    musicUrl = categorisedMusicListTwo["Simple"][selectedSize][randomIndex].url;
    musicName =
      categorisedMusicListTwo["Simple"][selectedSize][randomIndex].name;
  }

  const backgroundScript = {
    url: musicUrl,
    duration: 30,
    industry: industry,
    name: musicName,
    backgroundVolume: 0.05,
  };

  // const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl)
  setBackgroundMusic(backgroundScript);

  const randomTemplate = Math.floor(Math.random() * FONT_TEMPLATE.length);

  // Generate subtitles by default if free version
  // IS_FREE_PLATFORM === 'true' &&

  await generateSubtitles(
    "Poppins",
    FONT_TEMPLATE[0].wordsPerLine,
    30,
    store,
    FONT_TEMPLATE[0].wordsPerLine,
    FONT_TEMPLATE[0],
    true,
    store.height - store.height * 0.4,
    () => {
      true;
    },
    setScriptDetails
  );

  await setScriptDetails((prevScript: { data: any }) => {
    const updatedScript = { ...prevScript };
    return {
      ...updatedScript,
      backgroundMusic: backgroundScript,
      aiVoiceVolume: 1,
      thumbnailImage: thumbnailImage,
      transitionSound: false,
      audioFade: false,
      aiVoiceSpeed: 1,
    };
  });
  console.log(store.toJSON());
  store.selectPage(store.pages[0].id);
  store.history.clear();
}
