import { Carousel, Col, Row } from "antd";
import SingleCardStoryBoard from "./singleCardBoard";
import "./sectionStoryBoard.less";
import { useGetVoices } from "@src/api/elevenLabs/getVoices";
import { observer } from "mobx-react-lite";
const SectionStoryBoard = ({ store, scriptDetails }: any) => {
    const { data: aiVoice, isLoading, isSuccess } = useGetVoices();

  return (
    <div
      style={{
        display: "flex", // Aligns items horizontally
        overflowX: "auto", // Enables horizontal scrolling
        gap: "16px", // Adds space between cards
        padding: "10px",
        background:'var(--color-primary-200)',
        border:'3px solid var(--color-primary-400)',
        borderRadius:'10px',
        marginLeft:'10px'
      }}
      className="custom-scrollbar"
    >
      {scriptDetails?.data?.map((script: any, index: number) => (
        <SingleCardStoryBoard
          key={index}
          store={store}
          script={script}
          scriptIndex={index}
          aiVoice={aiVoice?.voices || []}
        />
      ))}
    </div>
  );
};

export default observer(SectionStoryBoard);
