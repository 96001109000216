import { NODE_BACKEND_URL } from "@src/config";
import axios from "axios";

export async function generateAiAvatarVideo(
  audio_url: string,
  replica_id: string,
  background_url: string,
  background_source_url: string,
  script: string,
  aiAvatarScript: boolean
) {
  const postBody = {
    script: (aiAvatarScript ||  !audio_url )? script : "",
    audio_url: audio_url,
    replica_id: replica_id || "rf7476ce9f",
    // replica_id: 'rf7476ce9f',

    background_url: background_url,
    background_source_url: background_source_url,
    background_scroll: background_url ? true : false,
    background_scroll_type: "human",
    fast:true
    // background_scroll_depth:background_url ? 'bottom' : '',
    // background_scroll_return:background_url ? 'halt' : ''
  };
  //   console.log(replica_id)
  try {
    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    });

    const response = await localEndPoint.post(
      "/api/v1/tavus/generate-video",
      postBody
    );
    return response.data;
  } catch {
    return undefined;
  }
}

export async function getAiAvatarVideo(video_id: string) {
  const postBody = {
    video_id: video_id,
  };
  try {
    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    });

    const response = await localEndPoint.post(
      "/api/v1/tavus/get-video",
      postBody
    );
    return response.data;
  } catch {
    return undefined;
  }
}

export async function deleteAiAvatarVideo(video_id: string) {
  const postBody = {
    video_id: video_id,
  };
  try {
    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    });

    const response = await localEndPoint.post(
      "/api/v1/tavus/delete-video",
      postBody
    );
    return response.data;
  } catch {
    return undefined;
  }
}

// export async function 
export const fetchReplicas = async (params:any) => {
  //   const axios = getProtectedAxios();
     
    const endpoint = `https://tavusapi.com/v2/replicas`;
    
    const { data } = await axios.get(endpoint, {
      headers: {
        'x-api-key': '7b0f3944726f429bb2336ce3342bbdf0',
      },
      params, 
    });
  
    return data.data;
  };