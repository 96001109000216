/* eslint-disable react/prop-types */
import { burstImageTransitionFunctions } from "../../../../src/data/transitions";
import { VideoPlayer } from "../../../../src/features/video/player/videoPlayer";
import {
  millisecondsToFrames,
  prefetchAsset,
} from "../../../../src/shared/utils/remotion";
import { Alert, Button, Modal, Row, Col, message } from "antd";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useGlobalContext } from "../../../context/globalContext";
import "./customPolotno/customActionControlContainer.less";
import {
  extractPagesFromHtml,
  addIdsToHtmlFromJson,
} from "../../../../src/shared/utils/remotion/htmlProcessing";
import { ScriptButton } from "./script/newScriptModal";
import { renderThumbnail } from "@src/features/video/player/renderStill";
import { LoadingOutlined, PlayCircleOutlined } from "@ant-design/icons";
import SectionStoryBoard from "./storyBoard/SectionStoryBoard";
import extractIdFromUrl from "@src/shared/utils/exractIdFromGettyUrl";
import {
  GettyQualityImage,
  GettyVideoDownloadSizes,
  getUserDetails,
} from "@src/shared/utils/VideoGeneration/helperFunctions/mediaSearch";
import { trackEvent } from "@src/lib/mixpanel";
import { preloadVideo } from "@src/shared/utils/preloadVideo";
import { generateUniqueId } from "@src/shared/utils/core";
import { getElementIdJsonPageTavusPayload } from "@src/shared/utils/findReplicaJson";
import {
  avatarRendering,
  checkRenderStatus,
  downloadAsync,
} from "@src/shared/utils/VideoGeneration/helperFunctions/aiAvatarAsync";
import { getCookie, setCookie } from "cookies-next";
import { observer } from "mobx-react-lite";
import { triggerDownload } from "@src/shared/utils/VideoGeneration/AssetDownload";

// a previw modal which plays the video
interface VideoPreviewProps {
  store: any; // Ideally, replace `any` with a more specific type
}
const assetIds: any[] = [];
const PreviewButtonSection: React.FC<VideoPreviewProps> = ({
  store,
}) => {
  console.log(store.toJSON());
  const draggleRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const taskId = getCookie("renderTask_id");

  const {
    TransitionAnimation: [transitionAnimation],
    ScriptDetails: [scriptDetails],
    VideoStyle: [videoStyle],
    ShowTextOverlay: [showTextOverlay, setShowTextOverlay],
    ProgressPercent: [progressBarPercent, setProgressBarPercent],
    PreviewLoader: [isPreviewLoading, setIsPreviewLoading],
    UserDetail: [userDetails, setUserDetails],
    CurrentUserData: [currentUserData],
    ShowAdvancedEditor: [showAdvancedEditor, setShowAdvancedEditor],
  } = useGlobalContext();
  const projectID = location.pathname.split("/").pop();
  // loading for when gif is rendering
  const [isGifRendering, setIsGifRendering] = useState<boolean>(false);
  const [generateuniqueRoomId, setGenerateUniqueRoomId] = useState("");
  const [IsVideoRender, setIsVideoRendering] = useState(false);
  // opens up the preview modal
  const showModal = () => {
    setIsGifRendering(true);
  };
  const waterMarkedAsset: { id: any; src: any }[] = [];

  const updateVideos = async () => {
    const currentDate = new Date().toISOString().split("T")[0];

    for (const page of store.pages) {
      for (const child of page.children) {
        if (child.type === "video") {
          const element = store.getElementById(child.id);
          const obj = { id: child.id, src: child.src };
          waterMarkedAsset.push(obj);
          const assetId = extractIdFromUrl(child.src);
          if (element && assetId) {
            if (child.custom) {
              try {
                const qualityUrl = await GettyVideoDownloadSizes(assetId);
                if (qualityUrl) {
                  element.set({ src: qualityUrl });
                  assetIds.push({
                    asset_id: assetId,
                    quantity: 1,
                    usage_date: currentDate,
                  });
                }
              } catch (error) {
                console.error(
                  `Failed to get video quality URL for id ${child.custom.gettyId}: `,
                  error
                );
              }
            }
          }
        } else if (child.type === "image") {
          const element = store.getElementById(child.id);
          console.log(child.src);
          const assetId = extractIdFromUrl(child.src);
          if (element && assetId) {
            if (child.custom) {
              try {
                const qualityUrl = await GettyQualityImage(assetId || "");

                if (qualityUrl) {
                  element.set({ src: qualityUrl });
                  assetIds.push({
                    asset_id: assetId,
                    quantity: 1,
                    usage_date: currentDate,
                  });
                }
              } catch (error) {
                console.error(
                  `Failed to get video quality URL for id ${child.custom.gettyId}: `,
                  error
                );
              }
            }
          }
        }
      }
    }
  };
  function setBackToWaterMark(waterMarkedAsset: any) {
    for (const page of store.pages) {
      for (const child of page.children) {
        if (child.type === "video") {
          const element = store.getElementById(child.id);
          for (const obj of waterMarkedAsset) {
            if (obj.id === child.id) {
              element.set({ src: obj.src });
            }
          }
        }
      }
    }
    waterMarkedAsset = [];
  }
  const handleRender = async () => {
    setIsVideoRendering(true);
  
    const avatarRenderResponse = await avatarRendering(
      store,
      scriptDetails,
      userDetails,
      videoStyle,
      transitionAnimation,
      projectID
    );
    console.log('hello')
    if (avatarRenderResponse) {
      setCookie("renderTask_id", avatarRenderResponse.task_id);
      message.info(
        "Video rendering has started. We will notify you with the download link via email once it is completed."
      );
      setIsVideoRendering(false);
      return
    } else {
      message.info("Failed to render");

      setIsVideoRendering(false);
      return
    }
    //   const getCredits = await getUserDetails();

    //   if (getCredits == -1) {
    //     message.warning("Something went wrong!");
    //     setIsVideoRendering(false)

    //     return;
    //   }
    //   if (getCredits?.video_limit < 1) {
    //     message.warning(
    //       "Video credits have been exhausted. Please upgrade the plan to download the video."
    //     );
    //     setIsVideoRendering(false)

    //     return;
    //   }
    //   if (waterMarkedAsset.length < 1) {
    //     await updateVideos();
    //   }
    //   trackEvent("Video", {
    //     name: currentUserData?.name,
    //     email: currentUserData?.email,
    //     message: `Download Started for project id: ${projectID}`,
    //     type: "INFO",
    //     submodule: "async_download",
    //   });
    //   const jsonData = await store.toJSON()
    //   const burstImagesTransitionAnimationArray = [];
    //   if (videoStyle === "Burst") {
    //     for (let i = 0; i < scriptDetails.data.length; i++) {
    //       const innerArray = [];

    //       for (let j = 0; j < scriptDetails.data[i].images.length; j++) {
    //         const randomFunction =
    //           burstImageTransitionFunctions[
    //             Math.floor(Math.random() * burstImageTransitionFunctions.length)
    //           ];
    //         // innerArray.push(randomFunction.value)
    //         innerArray.push(transitionAnimation[0].animation);
    //       }
    //       burstImagesTransitionAnimationArray.push(innerArray);
    //     }
    //   }
    //   const promises = await Promise.all(
    //     jsonData.pages.map(async (page: any) => {
    //       await prefetchAsset(page?.custom?.sounds[0]?.url);

    //       await Promise.all(
    //         page.children.map(async (child: any) =>
    //           (child.type === "video" || child.type === "image") && child.src
    //             ? child.type === "video" && preloadVideo(child.src)
    //             : Promise.resolve()
    //         )
    //       );
    //     })
    //   );
    //      /* this is done so that the caption text on polotno does not show up in the downloaded video.
    // the text shown on polotno when captions is enabled is only shown visually for the user*/
    // store.pages.map((page: any) => {
    //   const elementID = "captionText" + page.id;
    //   if (store.getElementById(elementID)) {
    //     store.getElementById(elementID).set({
    //       opacity: 0,
    //     });
    //   }
    // });
    //   // getting the html code
    //   const result = await store.toHTML();
    //   // converting the html code into different pages so it reflects each page in json
    //   const htmlCode = extractPagesFromHtml(result);
    //   const htmlCodeWithTags = await addIdsToHtmlFromJson(htmlCode, jsonData);
    //   const data = htmlCodeWithTags;
    //   const aiAvatar = userDetails?.purpose?.aiAvatar;
    //   const duration = await millisecondsToFrames(store.duration, 30);
    //   // getting transition duration from the global context
    //   const transitionDurationInFrames = await transitionAnimation[0].duration;
    //    // getting the transition function from the global context
    //    const transitionAnimationProp = await transitionAnimation[0].animation;

    //    const isDownload = false;
    //    if (scriptDetails?.showSubtitles) {
    //     store.pages.map((page: any) => {
    //       const elementID = "captionText" + page.id;
    //       if (store.getElementById(elementID)) {
    //         store.getElementById(elementID).set({
    //           opacity: 1,
    //         });
    //       }
    //     });
    //   }
    //   const roomId = generateUniqueId();
    //   setGenerateUniqueRoomId(roomId)
    //   const remotionProps={
    //     data,
    //     jsonData:{},
    //     duration,
    //     transitionDurationInFrames,
    //     transitionAnimationProp,
    //     isDownload,
    //     scriptDetails,
    //     burstImagesTransitionAnimationArray,
    //     videoStyle,
    //     isFree: true,
    //     generateuniqueRoomId,
    //     aiAvatar: aiAvatar,
    //   }
    //   const videoRawData=await getElementIdJsonPageTavusPayload(jsonData, scriptDetails, userDetails)

    //  const reponse = await downloadAsync(videoRawData, jsonData, remotionProps, projectID)

    //  setIsVideoRendering(false)
    //  setBackToWaterMark(waterMarkedAsset)
    message.info(
      "Video rendering has started. We will notify you with the download link via email once it is completed."
    );
  };
  // closes the preview modal
  const handleOk = () => {
    // shows the captions on polotno for user to see where the captions will be placed in the video
    store.history.ignore(async () => {
      store.pages.map((page: any) => {
        if (page?.custom?.showSubtitles === true) {
          const elementID = "captionText" + page.id;
          if (store.getElementById(elementID)) {
            store.getElementById(elementID).set({
              opacity: 1,
            });
          }
        }
      });
    });
    setShowAdvancedEditor(true);
  };

  // same concept as handle ok
  const handleCancel = () => {
    // setPreviewOpen(false);
    store.history.ignore(async () => {
      store.pages.map((page: any) => {
        if (page.custom.showSubtitles === true) {
          const elementID = "captionText" + page.id;
          if (store.getElementById(elementID)) {
            store.getElementById(elementID).set({
              opacity: 1,
            });
          }
        }
      });
    });
  };

  const [width, setWidth] = useState<number>(500);
  const [height, setHeight] = useState<number>(600);
  const [duration, setDuration] = useState<number>(1);
  const [htmlCode, setHtmlCode] = useState([]);
  const [burstImageAnimations, setBurstImageAnimations] = useState<any>([]);

  // below are functions to set the new values
  const changeHtmlCode = (newHtmlCode: any) => {
    setHtmlCode(newHtmlCode);
  };

  const changeDuration = (newDuration: number) => {
    setDuration(newDuration);
  };

  const changeWidth = (newWidth: any) => {
    let adjustedWidth = 704;
    if (newWidth == 1280) {
      adjustedWidth = 704;
    } else if (newWidth == 720) {
      adjustedWidth = 400;
    } else if (newWidth == 800) {
      adjustedWidth = 536;
    } else {
      adjustedWidth = 704;
    }

    setWidth(adjustedWidth);

    // const modalBody = document.querySelector('.ant-modal-body');
    // if (modalBody && modalBody.firstChild) {
    //   const firstChild = modalBody.firstChild as HTMLElement; // Casting to HTMLElement
    //   firstChild.style.width = `${adjustedWidth}px`;
    //   // firstChild.style.height = `${dynamicHeight}px`;
    //   // firstChild.style.backgroundColor = 'lightblue'; // Optional for visibility
    // }
  };

  const changeHeight = (newHeight: any) => {
    setHeight(newHeight);
  };

  // preview modal title
  const modalTitle = (
    <>
      <div
        style={{ width: "100%", cursor: "move" }}
        className="draggable-modal-title" // This class will be used as a handle
      >
        Video Preview
      </div>
      <Alert
        message="Any watermark on the video will be removed in the download."
        type="info"
        style={{
          fontSize: "12px",
        }}
      />
    </>
  );

  // will open up the modal
  const handlePreviewClick = async () => {
    showModal();
    // await renderThumbnail()
  };

  useEffect(()=>{
console.log('hello')
  }, [store])
  // a use effect to load all the props that are required for previewing a video
  useEffect(() => {
    // console.log(previewOpen)
    async function prefetchAndRender() {
      setIsGifRendering(true);

      // getting the json data from polotno
      const jsonData = store.toJSON();

      // if the video style is burst we make an array of random transition functions
      const burstImagesTransitionAnimationArray = [];

      if (videoStyle === "Burst") {
        for (let i = 0; i < scriptDetails.data.length; i++) {
          const innerArray = [];

          for (let j = 0; j < scriptDetails.data[i].images.length; j++) {
            const randomFunction =
              burstImageTransitionFunctions[
                Math.floor(Math.random() * burstImageTransitionFunctions.length)
              ];
            // innerArray.push(randomFunction.value)
            innerArray.push(transitionAnimation[0].animation);
          }
          burstImagesTransitionAnimationArray.push(innerArray);
        }

        setBurstImageAnimations(burstImagesTransitionAnimationArray);
      }

      // prefetching all the video or image url in browser to make sure that the video playing is not glitchy
      const promises = await Promise.all(
        jsonData.pages.map(async (page: any) => {
          // await prefetchAsset(page?.custom?.sounds[0]?.url)
          await prefetchAsset(
            Array.isArray(page?.custom?.sounds) && page.custom.sounds.length > 0
              ? page.custom.sounds[0].url
              : undefined
          );
          // Prefetch for all children in parallel
          await Promise.all(
            page.children.map((child: any) =>
              (child.type === "video" || child.type === "image") && child.src
                ? prefetchAsset(child.src)
                : Promise.resolve()
            )
          );
        })
      );
      // prefetchAsset(child.src, child.type === 'image' ? 'image/png' : 'video/mp4')
      // console.log(promises)

      // prefetching the background music so it plays without lag
      await prefetchAsset(scriptDetails?.backgroundMusic?.url);
      await prefetchAsset(
        scriptDetails?.customTransitionSound ||
          "https://d2tqfxp1334laf.cloudfront.net/image_projects/da764da2-2982-49be-88bd-eca414c4f5d7/assets/audio/e4cdb276-d1ad-4d81-91ae-3182d55c4099/audio.mp3"
      );
      // converting the duration thats in store which is in miliseconds into duration that remotion can understand. Fps is 30, can be changed in the furture
      changeDuration(millisecondsToFrames(store.duration, 30));

      /* this is done so that the caption text on polotno does not show up in the preview video.
    the text shown on polotno when captions is enabled is only shown visually for the user*/
      store.pages.map((page: any) => {
        const elementID = "captionText" + page.id;
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 0,
          });
        }
      });

      await store.pages.map((page: any) => {
        page.children.map((child: any) => {
          if (child.name === "h1-notlinked") {
            const elementID = child.id;
            if (store.getElementById(elementID)) {
              store.getElementById(elementID).set({
                visible: showTextOverlay,
                opacity: showTextOverlay ? 1 : 0,
                // custom.custom:
              });
            }
          }
        });
      });
      // console.log(store.toJSON())
      // getting the html code
      const result = await store.toHTML();
      // console.log(result)
      // converting the html code into different pages so it reflects each page in json
      const htmlCode = extractPagesFromHtml(result);

      // adding tags into that htmlCode array so that we can show animations
      const htmlCodeWithTags = addIdsToHtmlFromJson(htmlCode, jsonData);
      await prefetchAsset(scriptDetails?.customTransitionSoundurl);
      changeHtmlCode(htmlCodeWithTags);
      changeWidth(store.width);
      changeHeight(store.height);
      setIsGifRendering(false);
    }

    // if (previewOpen) {
    // calling the function
    prefetchAndRender();
    // }
  }, []);
  const handleCheckStatus = async () => {
    try{
      const status = await checkRenderStatus(taskId);
      message.info(status.status)
      if(status.data.final_video_url){
        triggerDownload(status.data.final_video_url, 'FinalVideo.mp4')
      }
      setCookie("renderTask_id", '')
    }catch{
      message.info('Render Failed')
      setCookie("renderTask_id", '')
    }
    // console.log(status)
    
    // if()
  };
  return (
    <>
      <Row
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          //   marginTop:'10px'
        }}
      >
        {/* VideoPlayer Column */}
        <Col
          flex="0 0 auto" // Dynamic width based on content or calculation
          style={{
            width: "auto", // Allows the content to define the width
            maxWidth: "70%", // Optional: Maximum width to prevent it from dominating,
            border: "3px solid var(--color-primary-400)",
            borderRadius: "10px",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isGifRendering ? (
              <div
                // center elements vertically
                style={{
                  width: store.width,
                  height: store.height,
                  display: "flex",
                  justifyContent: "center",
                  justifyItems: "center",
                  textAlign: "center",
                  marginTop: "auto",
                  flexDirection: "column",
                }}
              >
                <div>
                  <img
                    src="https://d19ep7nqjvgj4d.cloudfront.net/adgen-fe/ai-loader.gif"
                    style={{
                      width: "130px",
                      height: "130px",
                      // margin: 'auto',
                    }}
                  />
                  <p>Loading Preview...</p>
                </div>
              </div>
            ) : (
              <div
                style={{
                  borderRadius: "10px",
                  borderColor: "var(--color-primary-500)",
                  border: "1px",
                }}
              >
                <VideoPlayer
                  htmlData={htmlCode}
                  duration={duration}
                  scriptDetails={scriptDetails}
                  jsonData={store.toJSON()}
                  transitionDurationInFrames={transitionAnimation[0].duration}
                  transitionAnimationProp={transitionAnimation[0].animation}
                  isDownload={false}
                  burstImagesTransitionAnimationArray={burstImageAnimations}
                  videoStyle={videoStyle}
                  aiAvatar={userDetails?.purpose?.aiAvatar}
                />
              </div>
            )}
          </div>
        </Col>

        {/* SectionStoryBoard Column */}
        <Col
          flex="1" // Fills the remaining space
          style={{
            overflow: "auto", // Handles overflow if content is large
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              gap: "4px",
              paddingTop: "20px",
            }}
          >
            {taskId && (
              <Button
                onClick={() => {
                  handleCheckStatus();
                }}
                style={{
                  background: "var(--gradient-primary)",
                  borderColor: "transparent",
                  color: "white",
                }}
                disabled={IsVideoRender}
                loading={IsVideoRender}
              >
                Check Render Status
              </Button>
            )}
            <Button
              onClick={() => {
                handleRender();
              }}
              style={{
                background: "var(--gradient-primary)",
                borderColor: "transparent",
                color: "white",
              }}
              disabled={IsVideoRender}
              loading={IsVideoRender}
            >
              Render Video
            </Button>
            <Button
              onClick={() => {
                handleOk();
              }}
              style={{
                background: "transparent",
                borderColor: "var(--color-primary-500)",
                marginBottom: "10px",
                color: "var(--color-primary-500)",
              }}
            >
              Advanced Video Editor
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <SectionStoryBoard store={store} scriptDetails={scriptDetails} />
          </div>
          {/* <p>Hello</p> */}
        </Col>
      </Row>
    </>
  );
};

export default observer(PreviewButtonSection)