import type { TransitionPresentation, TransitionPresentationComponentProps } from "@remotion/transitions"; 
import React, { useMemo } from "react";
import { AbsoluteFill } from "remotion";

export type CustomPresentationProps = {
  width: string;
  height: string;
};

const ZoomOutPresentation: React.FC<
  TransitionPresentationComponentProps<CustomPresentationProps>
> = ({ children, presentationDirection, presentationProgress }) => {
  const scale = useMemo(() => {
    if (presentationDirection === "entering") {
      return 1 + (1 - presentationProgress); // Start larger and shrink to normal size
    } else if (presentationDirection === "exiting") {
      return 1 - presentationProgress; // Shrink during exiting
    }
    return 1;
  }, [presentationDirection, presentationProgress]);

  const style: React.CSSProperties = useMemo(() => {
    return {
      width: "100%",
      height: "100%",
      transform: `scale(${scale})`,
      transformOrigin: "center",
    };
  }, [scale]);

  return (
    <AbsoluteFill style={style}>
      {children}
    </AbsoluteFill>
  );
};

export default ZoomOutPresentation;

export const zoomOut = (
  props?: CustomPresentationProps
): TransitionPresentation<CustomPresentationProps> => {
  return {
    component: ZoomOutPresentation,
    props: props ?? { width: "100%", height: "100%" },
  };
};
