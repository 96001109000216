import React, { useEffect, useRef, useState } from "react";
import { Button, Tooltip } from "antd";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import "./VideoPlayer.less"; // Add custom styles if needed

interface VideoPlayerProps {
  src: string;
  borderColor?: string;
  isSelected?: boolean;
  maxH?: string;
}

export const VideoPlayerCustom: React.FC<VideoPlayerProps> = ({
  src,
  borderColor = "#1677ff", // Default Ant Design primary color
  isSelected = false,
  maxH = "40vh",
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hover, setHover] = useState(false);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    if (!isSelected) {
      handlePause();
    }
  }, [isSelected]);

  return (
    <div
      className={`video-container ${isSelected ? "selected" : ""}`}
      style={{
        position: "relative",
        border: isSelected ? `4px solid ${borderColor}` : "none",
        borderRadius: "8px",
        cursor: "pointer",
        display:'flex',
        justifyContent:'center',
        width:'100%'
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <video
        src={src}
        ref={videoRef}
        style={{
          borderRadius: "8px",
          maxHeight: maxH,
          // width: "auto",
          objectFit:'contain',
          width:'285px'
        }}
        onEnded={handleVideoEnd}
        muted
      />
      {!isPlaying ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tooltip title="Play Video">
            <Button
              type="primary"
              shape="circle"
              icon={<PlayCircleOutlined />}
              size="large"
              onClick={handlePlay}
            />
          </Tooltip>
        </div>
      ) : (
        hover && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title="Pause Video">
              <Button
                type="primary"
                shape="circle"
                icon={<PauseCircleOutlined />}
                size="large"
                onClick={handlePause}
              />
            </Tooltip>
          </div>
        )
      )}
    </div>
  );
};
