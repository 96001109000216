import { getDomainID, qaBackendApiInstance } from "../../../../config/config";

export async function generateSnapShot(url: string, orientation: string) {
  try {
    const response = await qaBackendApiInstance.post(
      `/video/url-snapshot-video`,
      {
        url: url||'https://www.quickads.ai/',
        orientation: orientation,
      }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }
}

export async function checkVideoGeneratedStatus(taskId: string) {
  try {
    const response = await qaBackendApiInstance.get(
      `/video/check-snapshot-video/${taskId}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return undefined;
    }
  } catch {
    return undefined;
  }
}
