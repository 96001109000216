import { fade } from "@remotion/transitions/fade";
import { slide } from "@remotion/transitions/slide";
import { wipe, WipeDirection } from "@remotion/transitions/wipe";
import { flip } from "@remotion/transitions/flip";
import { rotateTransition } from "../shared/utils/remotion/RemotionAnimations/rotationAnimation";
import { clockRotation } from "../shared/utils/remotion/RemotionAnimations/clockWiseRotationAnimation";
import { burstSlide } from "../shared/utils/remotion/RemotionAnimations/burstSlideAnimation";
import { burstWipe } from "../shared/utils/remotion/RemotionAnimations/burstWipeAnimations";
import { burstFlip } from "../shared/utils/remotion/RemotionAnimations/burstFlip";
import { shake } from "../shared/utils/remotion/RemotionAnimations/shakeAnimation";
import { glitch } from "../shared/utils/remotion/RemotionAnimations/glitchAnimation";
// import { swing } from '../shared/utils/RemotionAnimations/swingAnimation'
import { push } from "../shared/utils/remotion/RemotionAnimations/pushAnimation";
import { blurTransition } from "../shared/utils/remotion/RemotionAnimations/blurAnimation";
import { fadeTest } from "../shared/utils/remotion/RemotionAnimations/swingAnimation";
import { zoomIn } from "../shared/utils/remotion/RemotionAnimations/zoomInAnimation";
import { fadeToBlack } from "../shared/utils/remotion/RemotionAnimations/fadeInOut";
import { zoomOut } from "../shared/utils/remotion/RemotionAnimations/zoomOutAnimation";

// import { zoomInFadeOut } from "../shared/utils/remotion/RemotionAnimations/zoomFadeTrannsition";

// import { fadeOut } from '@src/shared/utils/remotion/RemotionAnimations/fadeOutTransitions'

// import { curtain } from '@src/shared/utils/RemotionAnimations/curtainAnimation'
const wipeDirection: WipeDirection = "from-left";
export const transitionAnimationsDropDown = [
  {
    value: "fade",
    label: "Fade",
    functions: fade(),
    // functions: fadeToBlack()
  },
  {
    value:'bottomwipe',
    label: 'Buttom Wipe',
    functions: wipe({direction:'from-top'})
  },

  {
    value:'topwipe',
    label: 'Top Wipe',
    functions: wipe({direction:'from-bottom'})
  },

  {
    value:'bottomright',
    label: 'Bottom Right Wipe',
    functions: wipe({direction:'from-top-left'})
  },


  {
    value:'bottomleft',
    label: 'Bottom Left Wipe',
    functions: wipe({direction:'from-top-right'})
  },

  {
    value: "slide",
    label: "Slide",
    functions: slide(),
  },
  {
    value: "wipe",
    label: "Wipe",
    functions: wipe(),
  },
  {
    value: "flip",
    label: "Flip",
    functions: flip(),
  },
  {
    value: "rotate",
    label: "Rotate",
    functions: rotateTransition(),
  },
  {
    value: "clock",
    label: "Clock",
    functions: clockRotation(),
  },
  {
    value: "shake",
    label: "Shake",
    functions: shake(),
  },
  {
    value: "glitch",
    label: "Glitch",
    functions: glitch(),
  },
  {
    value: "blur",
    label: "Blur",
    functions: blurTransition(),
  },
  {
    value: "zoomIn",
    label: "Zoom In",
    functions: zoomIn(),
  },

  {
    value: "zoomOut",
    label: "Zoom Out",
    functions: zoomOut(),
  },
  // { value: "ZoomFade", label: "Zoom Fade", funtions: zoomInFadeOut() },
];

export const burstImageTransitionFunctions = [
  {
    value: "fade",
    function: fade(),
  },
  // {
  //   value: 'slide',
  //   function: slide(),
  // },
  // {
  //   value: 'slide',
  //   function: burstSlide(),
  // },
  // {
  //   value: 'wipe',
  //   function: burstWipe(),
  // },
  // {
  //   value: 'flip',
  //   function: burstFlip(),
  // },
  // {
  //   value: 'glitch',
  //   function: glitch(),
  // },
  // {
  //   value: 'blur',
  //   function: blurTransition(),
  // },
  // {
  //   value: 'rotate',
  //   function: rotateTransition(),
  // },
  // {
  //   value: 'shake',
  //   function: shake(),
  // },
];
