import {
  ClockCircleOutlined,
  LoadingOutlined,
  PlayCircleFilled,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { TagSelect } from "../../../../../src/shared/components/filters/tagSelect";
import { Button, Input, InputNumber, Row, Switch, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../../context/globalContext";
import { transitionAnimationsDropDown } from "../../../../data/transitions";
import { aiTextToSoundEffect } from "@src/shared/utils/VideoGeneration/audioGeneration";
import { generateUniqueId } from "@src/shared/utils/core";
import { backgroundCover } from "@assets";
import { SectionTab } from "polotno/side-panel";
import { Clock02Icon } from "@src/features/polotno/component/hugeIcons/hugeIcons";
// import {Tooltip } from '@blueprintjs/core'

// import './transitionRow.tsx'
interface TransitionRowProps {
  pageID: string;
  store: any;
}

type SoundEffect = {
  // Define the structure of your sound effect here
  id: string;
  musicName: string;
  url: string;
  // other properties...
};

export const TransitionRow = ({ store, pageID }: TransitionRowProps) => {
  const {
    TransitionAnimation: [, setTransitionAnimation],
    ScriptDetails: [scriptDetails, setScriptDetails],
    ActiveSidePanel: [activeSidePanel, setActiveSidePanel],
  } = useGlobalContext();

  useEffect(() => {
    setActiveSidePanel(store.openedSidePanel);
  }, []);

  const [transitionDuration, setTransitionDuration] = useState<number>(30);
  const [tarnsitionValue, setTransitionValue] = useState<string>("fade");
  const [customSoundText, setCoustomSoundText] = useState("");
  const [soundEffectLoading, setSoundEffectLoading] = useState(false);
  const [generatedSounds, setGeneratedSounds] = useState<SoundEffect[]>([]);
  const handleTransitionValueChange = (selectedValue: string) => {
    setTransitionValue(selectedValue);
    console.log(selectedValue);
    setTransitionAnimation([
      { animation: selectedValue, duration: transitionDuration },
    ]);
  };

  const handleTransitionDurationChange = (value: number | null) => {
    if (typeof value === "number" && value >= 1) {
      setTransitionDuration(value);
      setTransitionAnimation([{ animation: tarnsitionValue, duration: value }]);
    }
  };

  const handleTransitionSound = (e: any) => {
    setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript };

      return { ...updatedScript, transitionSound: e };
    });
  };

  const handleOverlayAnimation=(e:any)=>{
    

    setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript };
      return { ...updatedScript, overlayAnimation: e };
    });

  }
  const handleCustomTransitionSwitch = (e: any) => {
    setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript };

      return { ...updatedScript, customtransitionSound: e };
    });
  };
  const handleCutsomSoundText = async (e: any) => {
    setSoundEffectLoading(true);
    console.log(scriptDetails);
    try {
      const soundeffectResponse = await aiTextToSoundEffect(customSoundText, 1);
      console.log(soundeffectResponse);
      setGeneratedSounds((prev) => [
        ...prev,
        {
          id: generateUniqueId(),
          musicName: customSoundText,
          url: soundeffectResponse,
        },
      ]);
      const generatedTransitionSoundss =
        scriptDetails?.generatedTransitionSounds || [];
      setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript };

        return {
          ...updatedScript,
          generatedTransitionSounds: [
            ...generatedTransitionSoundss,
            {
              id: generateUniqueId(),
              musicName: customSoundText,
              url: soundeffectResponse,
            },
          ],
        };
      });
    } catch (error) {
      console.log(error);
    } finally {
      setSoundEffectLoading(false);
    }
  };

  const playSound = (url: string) => {
    const audioPlayer = new Audio(url);
    audioPlayer
      .play()
      .then(() => {
        // Audio playback started
      })
      .catch((e) => {
        console.error("Error playing audio:", e);
      });
  };

  const handleUse = (url: string) => {
    setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript };
      return { ...updatedScript, customTransitionSoundurl: url };
    });
  };
  return (
    <>
      <Row gutter={[0, 4]} style={{ flexDirection: "column" }}>
        <Tooltip
          title={
            "Selecting this transition effect will automatically apply it to all scenes between the start and end, ensuring a consistent flow throughout."
          }
        >
          <h3
            style={{
              color: "black",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 500,
              // marginRight: '10px',
              letterSpacing: "normal",
              marginBottom: "10px",
            }}
          >
            Transition Effect:
          </h3>
        </Tooltip>
        <div style={{ marginRight: "20px" }}>
          <TagSelect
            dropdownItems={transitionAnimationsDropDown}
            placeholder={"Wipe"}
            defaultValue={tarnsitionValue}
            onChange={(value) => {
              handleTransitionValueChange(value);
            }}
            // style={{
            //   marginTop: '5px',
            // }}
          />
        </div>
        <h3
          style={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontWeight: "normal",
            marginRight: "15px",
          }}
        >
          Duration (ms)
        </h3>
        <InputNumber
          size="small"
          placeholder="Borderless"
          min={5}
          step={5}
          value={transitionDuration}
          onChange={handleTransitionDurationChange}
          style={{
            textAlign: "center",
            backgroundColor: "#f0f0f0",
            color: "#ffffff",
            borderColor: "#b3b3b3",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <h3
            style={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: "normal",
              marginRight: "15px",
            }}
          >
            Transition sound
          </h3>
          <Switch
            defaultChecked={scriptDetails?.transitionSound || false}
            value={scriptDetails?.transitionSound}
            onChange={(e) => handleTransitionSound(e)}
            style={{ marginTop: "10px", width: "fit-content" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Tooltip
            title={
              "Enabling custom sound effects allows the use of the generated sound."
            }
          >
            <h3
              style={{
                color: "black",
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 500,
                // marginRight: '10px',
                letterSpacing: "normal",
                marginBottom: "10px",
              }}
            >
              Custom sound effect:
            </h3>
          </Tooltip>
          <Switch
            defaultChecked={scriptDetails?.customtransitionSound || false}
            value={scriptDetails?.customtransitionSound || false}
            onChange={(e) => handleCustomTransitionSwitch(e)}
            style={{ marginTop: "10px", width: "fit-content" }}
          />
        </div>

        <div
          style={{
            pointerEvents: !scriptDetails?.customtransitionSound
              ? "none"
              : "auto",
            opacity: !scriptDetails?.customtransitionSound ? 0.5 : 1,
            backgroundColor: !scriptDetails?.customtransitionSound
              ? "#f9f9f9"
              : "",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Input
              value={customSoundText}
              placeholder="Ex: Bhoom, lion roar"
              onChange={(e: any) => {
                setCoustomSoundText(e.target.value);
              }}
              style={{
                width: "100%",
                marginRight: "10px",
              }}
            />
            <Button
              style={{ background: "var(--gradient-primary)", color: "white" }}
              onClick={handleCutsomSoundText}
              loading={soundEffectLoading}
              disabled={soundEffectLoading}
            >
              Generate
            </Button>
          </div>

          <h3
            style={{
              color: "black",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 500,
              // marginRight: '10px',
              letterSpacing: "normal",
              marginBottom: "10px",
            }}
          >
            Generated sounds:
          </h3>
          {scriptDetails?.generatedTransitionSounds?.map((sound: any) => (
            <div
              key={sound.id}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                marginTop: "3px",
              }}
            >
              <div>
                <span>{sound.musicName}</span>
                <PlayCircleFilled
                  onClick={() => {
                    // playVoiceSample(index < scriptDetails.data.length ? scriptDetails.data[index].voiceOver : '', )
                    playSound(sound.url);
                  }}
                  style={{
                    marginLeft: "10px",
                    color: "var(--color-primary-600)",
                    opacity: 1,
                  }}
                  className="icon-hover-effect"
                />
              </div>

              <Button
                // style={{background:'var(--gradient-primary)', color:'white' }}
                onClick={() => handleUse(sound.url)}
                // loading={}
                disabled={sound.url === scriptDetails?.customTransitionSoundurl}
                style={{
                  background:
                    sound.url === scriptDetails?.customTransitionSoundurl
                      ? "grey"
                      : "var(--gradient-primary)",
                  color:
                    sound.url === scriptDetails?.customTransitionSoundurl
                      ? "black"
                      : "white",
                }}
              >
                {sound.url === scriptDetails?.customTransitionSoundurl || ""
                  ? "In Use"
                  : "Use"}
              </Button>
            </div>
          ))}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <h3
            style={{
              fontFamily: 'DM Sans',
              fontSize: '14px',
              fontWeight: 'normal',
              marginRight: '15px',
            }}
          >
            Overlay Animation
          </h3>
          <Switch
            defaultChecked={scriptDetails?.overlayAnimation || false}
            value={scriptDetails?.overlayAnimation}
            onChange={(e) => handleOverlayAnimation(e)}
            style={{ marginTop: '10px', width: 'fit-content' }}
          />
        </div>
      </Row>
    </>
  );
};

export const CustomTransitionPolotno = {
  name: "transition-panel",
  Tab: (props: any) => (
    <SectionTab name="Transition" {...props}>
      <Clock02Icon className="sidebar-panel-tab-icon" />
    </SectionTab>
  ),
  Panel: TransitionRow,
};
